import React from 'react';
import PropTypes from 'prop-types'
import pug from "pug";
import { Container, Loader } from "./fullScreenLoaderStyle"

export const FullScreenLoader = (props) => {
    return (
        pug`
          Container
            Loader(color=props.color lightColor=props.lightColor size=props.size borderWidth=props.borderWidth)
        `
    )
}

FullScreenLoader.propTypes = {
    color: PropTypes.string,
    lightColor: PropTypes.string,
    size: PropTypes.number,
    borderWidth: PropTypes.number
}