import { createSlice } from '@reduxjs/toolkit'

export const exchangeSlice = createSlice({
  name: 'exchange',
  initialState: {
    slippageTolerance: "auto",
    recentTransactions: [],
    deadlineTime: 0,
    tokens: [
      {
        id: 1,
        name: "CVN",
        balance: 0,
      },
      {
        id: 2,
        name: "CVNX",
        balance: 0,
      },
    ],
    fromToken: "CVN",
    toToken: "CVNX",
    minGas: 0.000121,
  },
  reducers: {
    setSlippageTolerance: (state, action) => {
      state.slippageTolerance = action.payload;
    },
    setDeadlineTime: (state, action) => {
      state.deadlineTime = action.payload;
    },
    setToken: (state, action) => {
      if(action.payload.type === "from") {
        for(let i = 0; i < state.tokens.length ; i++) {
          if(action.payload.id === state.tokens[i].id) {
            state.fromToken = state.tokens[i].name;

            // replace other token if user select same as other
            if(state.tokens[i].name === state.toToken) {
              if(i+1 < state.tokens.length) state.toToken = state.tokens[i + 1].name;
              else state.toToken = state.tokens[i - 1].name;
            }
            break;
          }
        }
      }
      else if (action.payload.type === "to"){
        for(let i = 0; i < state.tokens.length ; i++) {
          if(action.payload.id === state.tokens[i].id) {
            state.toToken = state.tokens[i].name;
            
            // replace other token if user select same as other
            if(state.tokens[i].name === state.fromToken) {
              if(i+1 < state.tokens.length) state.fromToken = state.tokens[i + 1].name;
              else state.fromToken = state.tokens[i - 1].name;
            }
            break;
          }
        }
      }
    },
    replaceTokenWithEachOther:(state, action) => {
      let toToken = state.toToken;
      state.toToken = state.fromToken;
      state.fromToken = toToken;
    },
    setBalance: (state, action) => {
      // console.log(action)
      if(action.payload){
        // console.log("clean particular bal")
        state.tokens = state.tokens.map(item => (
          item.name === action.payload.symbol ? { ...item, balance: action.payload.balance } : item
        ))
      } else {
        // console.log("clear all balance")
        state.tokens = state.tokens.map(token => ({...token, balance: 0}))
      }
    }
  },
})

// Action creators are generated for each case reducer function
export const { setSlippageTolerance, setBalance, replaceTokenWithEachOther, setToken , setDeadlineTime } = exchangeSlice.actions

export default exchangeSlice.reducer