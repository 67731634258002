import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from "web3";
import { devices, INFURA_ID, NETWORKS, STORAGE, SYMBOLS, WALLETS } from "../constants/appConstants";
import { CVN_ERC_20_ABI } from "../cvn20";
import { CVNX_ABI } from "../cvnx-abi";
import { setWalletAddress } from "../redux/slices/dappSlice";
import { setBalance } from "../redux/slices/exchangeSlice";
import { CVNX_TOKEN, CVN_TOKEN } from "../constants/appConstants";
import { deleteLocalStorage } from "./helpers/localStorage";
import WallectConnect from '@walletconnect/client';
import { isMobile } from "react-device-detect";

// getting tokens balance from wallet
export const getBalance = async (walletAddress, contract, tokens) => {
  try {
    const balance = await contract.methods.balanceOf(walletAddress).call();
    return balance;
  } catch (error) {
    console.log(error)
    return false;
  }
};

// create provider
export const createProvider = async (walletType, walletsession) => {
  if ([WALLETS.metamask, WALLETS.trustWallet].includes(walletType)) return Web3.givenProvider;

  else if (walletType === WALLETS.walletConnect) {
    const options = { infuraId: INFURA_ID };

    if (walletsession) {
      const connector = new WallectConnect({
        bridge: 'https://bridge.walletconnect.org',
        storageId: 'walletconnect',
      });
      connector.session = walletsession;
      options.connector = connector;
      const provider = new WalletConnectProvider(options);
      provider.enable();
      return provider;
    } else {
      return new WalletConnectProvider(options);
    }
  } 
  
  else return Web3.givenProvider;
};

// switch network
export const switchNetwork = async (web3Instance) => {
  try {
    await web3Instance.currentProvider.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: NETWORKS.mainnetId }],
    });
  } catch (error) {
    if (error.code === 4902) {
      try {
        await web3Instance.currentProvider.request({
          method: "wallet_addEthereumChain",
          params: [
            { chainId: NETWORKS.mainnetId },
          ],
        });
      } catch (error) {
        alert(error.message);
        return false;
      }
    } else if (error.code === 4001) {
      return false;
    }
  }
}

// getting tokens balance from redux
export const getBalanceFromStore = async (tokens, symbol) => {
  try {
    const token = tokens.find(item => item.name === symbol);
    return token.balance;
  } catch (error) {
    console.log(error)
    return false;
  }
};

// return contract according to selected token
export const getContract = async (web3, fromToken) => {
  try {
    if (fromToken === SYMBOLS.cvn)
      return new web3.eth.Contract(CVN_ERC_20_ABI, CVN_TOKEN);
    else if (fromToken === SYMBOLS.cvnx)
      return new web3.eth.Contract(CVNX_ABI, CVNX_TOKEN);
  } catch (err) {
    console.log(err)
    return false;
  }
}

// copy to clipboard
export const copyToClipboardByRef = async (ref) => {
  try {
    let copyText = ref.current && ref.current.innerText;
    navigator.clipboard.writeText(copyText);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

// get token address based on symbol
export const getTokenAddress = async (symbol) => {
  try {
    if (symbol === SYMBOLS.cvn) return CVN_TOKEN;
    else if (symbol === SYMBOLS.cvnx) return CVNX_TOKEN;
  } catch (error) {
    console.log(error)
  }
};

// DetectDevice
export const detectDevice = () => isMobile ? devices.mobile : devices.desktop

// exctract no of item from array
export const getNoOfDataFromArr = async (noOfData, arr) => {
  try {
    let newArr = [];
    for (let i = 0; i < noOfData; i++) {
      arr[i] && newArr.push(arr[i])
    }
    return newArr;
  }
  catch (err) {
    console.log(err)
  }
}

//disconnect wallet , clear localStorage , clear redux
export const disconnectWallet = (dispatch) => {
  dispatch(setWalletAddress([]));
  dispatch(setBalance());
  deleteLocalStorage(STORAGE.walletAddress);
  deleteLocalStorage(WALLETS.walletType);
  deleteLocalStorage("allowance");
  deleteLocalStorage("walletconnect");
}