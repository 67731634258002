import React, { Suspense } from 'react'
import { GlobaStyle } from './styled/globalStyle';
import { ThemeProvider } from "styled-components";

import { lightMode, darkMode } from './styled/theme';
import { BrowserRouter } from "react-router-dom"

import { useSelector } from "react-redux"
import App from './App';
import './i18n'

const Root = () => {
  const theme = useSelector(state => state.dapp.theme);
  
  return (
    <ThemeProvider theme={theme === "light" ? lightMode : darkMode}>
      <GlobaStyle />
      <BrowserRouter>
          <App />
      </BrowserRouter>
    </ThemeProvider>

  )
}

export default Root
