import React, { Suspense } from 'react'
import pug from "pug"
import { Switch, Route,  Redirect } from "react-router-dom"
import Loader from "../components/loader/Loader"
import { useSelector } from 'react-redux'
import NotFound from "../pages/notFound"

const Swap = React.lazy(() => import('../pages/swap/Swap'))
const Home = React.lazy(() => import('../pages/home/Home'))
const ConfirmSwap = React.lazy(() => import('../pages/swap/confirm/ConfirmSwap'))

const Routes = () => {
  const { walletAddress } = useSelector(({ dapp }) => dapp);

  return (
    pug`
      Switch
        Route(path="/" exact)
          Suspense(fallback=Loader())
            Home

        Route(path="/swap" exact)
          Suspense(fallback=Loader())
            Swap
          
        Route(path="/swap/confirm" exact=true)
          if walletAddress.length
            Suspense(fallback=Loader())
              ConfirmSwap
            
          else 
            Redirect(to="/")

        Route(component=NotFound)
    `
  )
}

export default Routes
