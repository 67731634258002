import styled from "styled-components"
import { flexBetween, flexCenter, linksFill, linksFillH, linksStroke, linksStrokeH } from "../../styled/mixins";

export const DrawerInnerC = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background: ${p => p.theme.colors.main};
`;
export const DrawerHeader = styled.header`
  ${flexBetween};
  align-items: flex-start;
  padding: 1rem;
`;
export const Items = styled.div`
  ${flexCenter};

  .img-btn {
    width: 40px;
    padding: 5px;
    ${flexCenter};
    border-radius: 50%;
    border: 1px solid ${p => p.theme.colors.borderColor};
  }

  p {
    width: 30px;
  }

  .close-icon {
    height: ${p => p.theme.circleIcon.size};
    width: ${p => p.theme.circleIcon.size};
    padding: 5px;
    border-radius: 50%;
    border: 1px solid ${p => p.theme.colors.borderColor};
    cursor: pointer;
    color: ${p => p.theme.colors.text.main};
    margin: 0 10px;
  }
`;
export const CloseIcon = styled.div`
    ${linksStrokeH};
    .links-stroke {
        ${linksStroke};
    }

    ${linksFillH};
    .links-fill {
        ${linksFill};
    }

  .close-icon > div {
    height: 100%;
    ${flexCenter};
  }
`;
export const DrawerContent = styled.div`
  flex: 1;
  display: grid;
  place-content: center;

  .inner-div {
    padding: 0 0 2rem 0;

    a, p {
      font-family: ${p => p.theme.fontF.poppins};
      font-weight: ${p => p.theme.fontWeight.medium};
    }

    &>button, &>a {
      border: none;
      outline: none;
      background: transparent;
      display: flex;
      align-items:center;
      margin: ${p => p.theme.spacings.md} 0;
      cursor: pointer;

      .svg-con {
        width: ${p => p.theme.circleIcon.size};
        height: ${p => p.theme.circleIcon.size};
        border: 1px solid ${p => p.theme.colors.borderColor};
        margin-right: ${p => p.theme.spacings.sm};
        ${flexCenter};
        border-radius: 50%;

        div {
          ${flexCenter};
        }

        svg {
          font-size: 1.52rem;
        }
      }

      p {
        color: ${p => p.theme.colors.text.links};
        font-size: 1.1rem
      }

    }

    &:last-child {
     padding: 2rem 0 0 0;
    }

    &>a, &>button {
      ${linksStrokeH};
      .links-stroke {
          ${linksStroke};
      }

      ${linksFillH};
      .links-fill {
          ${linksFill};
      }
    }
  }
`;