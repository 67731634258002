import styled from "styled-components"
import { flexCenter, linksFill, linksFillH, linksStroke, linksStrokeH, linksStrokeHFooter, PoppinsMedium } from "../../styled/mixins";

export const Container = styled.div`
    border-radius: 50%;
    ${flexCenter};
    cursor:pointer;
    position: relative;

    &>.img-btn {
        padding: 5px;
        border: 1px solid ${p => p.theme.colors.borderColor};
        border-radius: 50%;
        ${flexCenter};
        max-width: 40px;

        &:hover {
         border-color: ${p => p.theme.colors.green};
        }
    }

    img {
        width: 100%;
        border: none !important;
        object-fit: contain;
    }

    /* ${p => p.theme.devices.xl} {
        &>.img-btn {
            padding: 10px;
            max-width: 60px;
        }
    }

    ${p => p.theme.devices.xxl} {
        &>.img-btn {
            padding: 10px;
            max-width: 80px;
        }
    } */
`;
export const Dropdown = styled.div`
    position: absolute;
    background: ${p => p.theme.colors.lightMain};
    box-shadow: ${p => p.theme.shadows.one};
    top: 120%;
    left: 50%;
    transform: translateX(-50%);
    padding: 1rem;
    border-radius: 24px;
    cursor: auto;
    border: 1px solid ${p => p.theme.colors.borderColor};
    z-index: 1;

    .chevron {
        margin: 0 8px;
        transform: rotate(90deg)
    }
`;
export const Button = styled.button`
    background: transparent;
    border: none;
    outline: none;
    margin: 10px 0;
    ${flexCenter};
    cursor: ${p => !p.inactive ? "pointer" : "auto"};

    ${p => !p.inactive && linksStrokeH};
    .links-stroke {
        ${p => !p.inactive && linksStroke};
    }

    ${p => !p.inactive && linksFillH};
    .links-fill {
        ${p => !p.inactive && linksFill};
    }

    p {
        color: ${p => p.inactive ? p.theme.colors.dark2 : p.theme.colors.text.links} !important;
        font-size: 0.9rem;
        ${PoppinsMedium};
        width: 25px;
    }

    &:hover {
        ${p => !p.inactive && linksStrokeHFooter};
        .links-fill {
            fill: ${p => !p.inactive && p.theme.colors.green};
        }
        p {
            color: ${p => !p.inactive && p.theme.colors.green} !important;
        }
    }

    .img-btn {
        cursor: ${p => !p.inactive ? "pointer" : "auto"} !important;
        padding: 5px;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        position: relative;
        filter:  ${p => p.inactive && "grayscale(100%)"};
    }

    /* ${p => p.theme.devices.xxl} {
        .img-btn {
            padding: 10px;
        }
    } */
`;