import styled from "styled-components"
import {  flexCenter, RobotoRegular } from "../../../../styled/mixins";

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 8px 0;
  padding-right: 1rem;

  ${p => p.theme.devices.lg} {
    margin: 12px 0;
  }
  ${p => p.theme.devices.xl} {
    margin: 1rem 0;
  }
  ${p => p.theme.devices.xxl} {
    margin: 1.4rem 0;
  }
`;
export const TransactionContent = styled.div`
  flex:1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${RobotoRegular};
  font-size: 0.9rem;
  color: ${ p => p.theme.colors.text.transaction };
  padding-right: 10px;

  ${p => p.theme.devices.lg} {
    font-size: 1.1rem;
  }
  ${p => p.theme.devices.xl} {
    font-size: 1.3rem;
  }
  ${p => p.theme.devices.xxl} {
    font-size: 1.5rem;
  }
`;
export const TransactionStatus = styled.div`
  border: 1px solid ${p => p.theme.colors.borderColor};
  border-radius: 50%;
  padding: 5px;
  ${flexCenter};
  width: ${p => p.theme.circleIcon.size};
  height: ${p => p.theme.circleIcon.size};

  svg {
    color: ${p => p.theme.colors.green};
    width: 90%;
    height: 90%;
  }

  .links-stroke {
    stroke: ${p => p.theme.colors.red};
  }

  ${p => p.theme.devices.lg} {
    width: 50px;
    height: 50px;
  }
`;
