import { createSlice } from '@reduxjs/toolkit'

export const featuresSlice = createSlice({
  name: 'features',
  initialState: {
    drawerOpen: false,
    termsAndConditionModal: false
  },
  reducers: {
    openDrawer: state => { state.drawerOpen = true },
    closeDrawer: state => { state.drawerOpen = false },
    openTermsAndConditionModal: state => { state.termsAndConditionModal = true },
    closeTermsAndConditionModal: state => { state.termsAndConditionModal = false },
  },
})

// Action creators are generated for each case reducer function
export const { openDrawer, closeDrawer, openTermsAndConditionModal,
  closeTermsAndConditionModal } = featuresSlice.actions

export default featuresSlice.reducer