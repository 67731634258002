import styled, { css, keyframes } from "styled-components";

export const flexBetween = css`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
`;
export const flexCenter = css`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
`;
export const Logo = css`
    font-size: 1.5rem;
    color: ${p => p.theme.colors.lightBlack};
    text-transform: uppercase;

    ${p => p.theme.devices.lg} {
        font-size: 2rem;
    }

    ${p => p.theme.devices.xl} {
        font-size: 2.3rem;
    }
`;
export const Btn = css`
    display: inline-block;
    padding: 10px 32px;
    font-size: 1rem;
    outline: none;
    border-radius: ${p => p.theme.radius.r2};
    background: ${p => p.theme.colors.button.background};
    color: ${p => p.theme.colors.button.text};
    text-transform: uppercase;
    font-weight: ${p => p.theme.fontWeight.medium};
    font-family: ${p => p.theme.fontF.poppins};
    cursor: pointer;
    -webkit-transition: background 0.1s linear;
    -o-transition: background 0.1s linear;
    transition: background 0.1s linear;
    white-space: nowrap;
    border: none;

    &:focus {
        background: transparent;
        color: ${p => p.theme.colors.button.background};
        border: 1px solid ${p => p.theme.colors.button.background};
    }

    /* ${p => p.theme.devices.lg} {
        font-size: 1.2rem;
        padding: 8px 24px;
        border-radius: 1.5rem;
    }

    ${p => p.theme.devices.xl} {
        font-size: 1.4rem;
        border-radius: 2rem;
    } */
`;
export const BtnWithHover = css`
    ${Btn}
    &:hover {
        background: ${p => p.theme.colors.violet};
        color: ${p => p.theme.colors.white};
    }
`;
export const BtnWithHover2 = css`
     ${Btn}
     background: ${p => p.theme.colors.violet};
    color: ${p => p.theme.colors.white};
    &:hover {
        background: transparent;
        color: ${p => p.theme.colors.violet};
    }
`;
export const rotate = keyframes`
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
`;
export const rotateReverse = keyframes`
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(-360deg);
    }
`;
export const removeNumberInputArrows = css`
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
        margin: 0;
    }
`;
export const resetInputStyle = css`
  border: none;
  outline: none;
  background: transparent;

  &:focus {
    background: none;
  }
`;
export const FlexDiv = styled.div`
    display: flex;
    align-items: center;
`;
export const FlexCenterDiv = styled.div`
    ${flexCenter};
`;
export const RobotoRegular = css`
    font-weight: ${p => p.theme.fontWeight.regular};
    font-family: ${p => p.theme.fontF.roboto};
`;
export const RobotoMedium = css`
    font-weight: ${p => p.theme.fontWeight.medium};
    font-family: ${p => p.theme.fontF.roboto};
`;
export const RobotoBold = css`
    font-weight: ${p => p.theme.fontWeight.bold};
    font-family: ${p => p.theme.fontF.roboto};
`;
export const PoppinsSemiBold = css`
    font-weight: ${p => p.theme.fontWeight.semiBold};
    font-family: ${p => p.theme.fontF.poppins};
`;
export const PoppinsRegular = css`
    font-weight: ${p => p.theme.fontWeight.regular};
    font-family: ${p => p.theme.fontF.poppins};
`;
export const PoppinsMedium = css`
    font-weight: ${p => p.theme.fontWeight.medium};
    font-family: ${p => p.theme.fontF.poppins};
`;
export const scrollbar = css`
    /* width */
    &::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
         background: ${p => p.theme.colors.borderColor}; 
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: ${p => p.theme.colors.text.main}; 
    }
`;
export const linksStroke = css`
    stroke: ${p => p.theme.colors.text.links};
`;

export const linksStrokeH = css`
    &:hover, &:hover .img-btn ,&:hover .svg-con {
        border-color: ${p => p.theme.colors.green};
    }
    &:focus {
        border-color: ${p => p.theme.colors.borderColor};
    }
    &:focus .img-btn, &:focus .svg-con {
        border-color: ${p => p.theme.colors.borderColor};
    }
    &:focus a, &:focus p {
        color: ${p => p.theme.colors.green};
    }
    &:focus .links-stroke {
        stroke: ${p => p.theme.colors.green};
    }
`;
export const linksFill = css`
    fill: ${p => p.theme.colors.text.links};
`;
export const linksFillH = css`
     &:hover .img-btn {
        border-color: ${p => p.theme.colors.green};
    }
    &:focus .img-btn {
        border-color: ${p => p.theme.colors.borderColor};
    }
    &:focus a, &:focus p {
        color: ${p => p.theme.colors.green};
    }
    &:focus .links-fill {
        fill: ${p => p.theme.colors.green};
    }
`;
export const imgFill = css`
    .image-fill {
        fill: ${p => p.theme.colors.main};
    }
    .image-fill-reverse {
        fill: ${p => p.theme.colors.text.main} !important;
    }
    .image-fill-border {
        fill: ${p => p.theme.colors.borderColor};
    }
    .image-stroke {
        stroke: ${p => p.theme.colors.borderColor};
    }
`;

export const linksStrokeFooter = css`
    stroke: ${p => p.theme.colors.text.footer};
`;
export const linksFillFooter = css`
    fill: ${p => p.theme.colors.text.footer};
`;
export const linksStrokeHFooter = css`
    &:hover .links-stroke {
        stroke: ${p => p.theme.colors.green};
    }
`;
export const linksFillHFooter = css`
    &:hover .links-fill {
        fill: ${p => p.theme.colors.green};
    }
`;