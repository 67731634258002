require('dotenv').config();

export const STORAGE = {
  walletAddress: "walletAddress",
  themeMode: "themeMode"
};

export const THEMES = {
  light: "light",
  dark: "dark"
};

export const LANGUAGE = {
  usEnglish: "en-US",
  english: "en",
  german: "de",
  turkish: "tr",
  spanish: "es",
  chinese: "cn"
}

export const WALLETS = {
  walletType: "walletType",
  metamask: "MetaMask",
  walletConnect: "WalletConnect",
  trustWallet: "TrustWallet"
};

export const SYMBOLS = {
  cvn: "CVN",
  cvnx: "CVNX",
};

export const CONSTANT = {
  approve: "approve",
  swap: "Start Swap"
};

export const devices = {
  desktop : "Desktop",
  mobile: "Mobile"
}

export const INFURA_ID = "7ef6a9437b6340b8b8899e550cc75ef1";

// rinkeby test network 

// export const NETWORKS = {
//   mainnetId: "0x4",
//   mainnet: "rinkeby"
// }
// export const CVNX_TOKEN = "0x587030e8044dd5bf7ad5eb6602dfe7667c7db1c4";
// export const CVN_TOKEN = "0xc2d941a82660790b65e886020cafed2ee65d8ed1";




// ethereum mainnet network

export const CVNX_TOKEN = "0xa1a4e303e9c56962f201c5e834abc1e677a3c4f3";
export const CVN_TOKEN = "0x62aaf435273bc4baa78dcebd6590042d7e58ba6f";
export const NETWORKS = {
  mainnetId: "0x1",
  mainnet: "main"
}



export const errMsg = {
  // MAINNET_NETWORK_ERROR: "Please make sure you are Connected to the Rinkeby Network in your wallet!",
  MAINNET_NETWORK_ERROR: "Please make sure you are Connected to the Ethereum Main Network in your wallet!",
  EMPTY_AMOUNT: "Amount can't be empty",
  USER_REJECTED_APPROVAL: "User denied transaction signature",
  INSTALL_META: "The wallet hasn’t been installed on your device. Please download and install it first.",
  INSTALL_TRUST: "The wallet hasn’t been installed on your device. Please download and install it first.",
  USER_REJECTED_REQUEST: "Please Authorize to access your account",
  TERMS_AND_CONDITION: "Please Accept the terms and condition",
  APPROVAL_UNSECCESFULL: "Approval Unsuccessfull",
  SWAP_UNSECCESSFULL: "Swap Unseccessfull",
  LOW_ETHER: "Low ethereum Add ETH in your wallet before swap",
  SWITCH_NETWORK: "Please Switch network to Rinkeby"
}