import React from 'react';
import pug from "pug";
import PropTypes from "prop-types";
import { useDispatch } from 'react-redux';
import Drawer from '@mui/material/Drawer';
import { DrawerInnerC, DrawerHeader, Items, DrawerContent, CloseIcon } from "./drawerStyle"
import { Link } from "react-router-dom"
import LanguageDropdown from '../languageDropdown/LanguageDropdown';
import { Logo } from "../../partials/header/headerStyle"
import { STORAGE, THEMES } from '../../constants/appConstants';
import { changeTheme } from '../../redux/slices/dappSlice';
import { setLocalStorage } from '../../common/helpers/localStorage';
import { ReactSVG } from 'react-svg'
import LogoSVG from "../../assets/logo.svg"
import CloseIconSVG from '../../assets/times.svg'
import SwapSVG from "../../assets/swap.svg"
import StakingSVG from "../../assets/staking.svg"
import VotingSVG from "../../assets/voting.svg"
import MoonSVG from "../../assets/moon.svg"
import SunSVG from "../../assets/sun.svg"
import { useTranslation } from 'react-i18next';

const styles = {
  drawerPaper: {
    width: '100%',
  },
};

const DrawerComponent = (props) => {
  const dispatch = useDispatch();
  const {t} = useTranslation()
  const { drawer, handleCloseDrawer } = props;

  const switchDarkMode = () => {
    setLocalStorage(STORAGE.themeMode, THEMES.dark);
    dispatch(changeTheme({ theme: THEMES.dark }))
  }

  const switchLightMode = () => {
    setLocalStorage(STORAGE.themeMode, THEMES.light);
    dispatch(changeTheme({ theme: THEMES.light }));
  }

  return (
    pug`
      Drawer(anchor="left" classes={paper: styles.drawerPaper} open=drawer onClose=handleCloseDrawer)
        DrawerInnerC
          DrawerHeader
            Logo(to="/")
              ReactSVG(src=LogoSVG)

            Items
              LanguageDropdown
              CloseIcon
                ReactSVG(src=CloseIconSVG className="close-icon icon" onClick=handleCloseDrawer)

          DrawerContent
            div
              div(className="inner-div")
                Link(to="/swap" onClick=handleCloseDrawer)
                  div(className="svg-con")
                    ReactSVG(src=SwapSVG)
                    
                  p #{t('swap')}
                
                Link(to="/" onClick=handleCloseDrawer)
                  div(className="svg-con")
                    ReactSVG(src=StakingSVG)
                    
                  p #{t('staking')}

                Link(to="/" onClick=handleCloseDrawer)
                  div(className="svg-con")
                    ReactSVG(src=VotingSVG)
                    
                  p #{t('voting')}

              div(className="inner-div")
                button(onClick=switchLightMode)
                  div(className="svg-con")
                    ReactSVG(src=SunSVG)

                  p #{t('Day')}
                      
                button(onClick=switchDarkMode)
                  div(className="svg-con")
                    ReactSVG(src=MoonSVG)

                  p #{t('NIght')}
    `
  )
}

DrawerComponent.propTypes = {
  drawer: PropTypes.bool,
  handleCloseDrawer: PropTypes.func,
};

export default DrawerComponent
