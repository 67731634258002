import { configureStore } from '@reduxjs/toolkit'
import dappReducer from './slices/dappSlice'
import exchangeReducer from './slices/exchangeSlice'
import basicFeaturesReducer from './slices/basicFeaturesSlice'

export default configureStore({
  reducer: {
    dapp: dappReducer,
    exchange: exchangeReducer,
    features: basicFeaturesReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false})
})