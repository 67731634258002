import React, { memo, useEffect, useRef } from 'react'
import pug from "pug"
import PropTypes from 'prop-types';
import Transactions from "./components/transactions";
import {getLocalStorage} from "../../common/helpers/localStorage"
import {WALLETS} from "../../constants/appConstants"
import WalletDetails from "./components/walletDetails"

//SVG
import { ReactSVG } from 'react-svg';
import CloseIconSVG from "../../assets/times.svg"

// styled components
import { Wrapper,Header, Headings, Heading, SubHeading ,Container,  CloseLogo } from './accountDetailsStyle'
import { useTranslation } from 'react-i18next';

export const AccountDetails = memo((props) => {
  const {t} = useTranslation()
  const walletType = useRef("");

  useEffect(() => {
    (async () => {
      const wallet = await getLocalStorage(WALLETS.walletType);
      if(wallet) walletType.current = wallet;
    })();
  },[]);

  return (
    pug`
      Wrapper
        Header
          CloseLogo(onClick=props.handleClose)
            ReactSVG(src=CloseIconSVG)
        
        Headings
          Heading #{t('Account details')}
          SubHeading Connected with #{walletType.current}

        Container 
          WalletDetails(handleClose=props.handleClose)

          Transactions
    `
  );
})

AccountDetails.propTypes = {
  handleClose: PropTypes.func
};