import styled from "styled-components"
import { Btn, flexBetween, flexCenter } from "../../../styled/mixins";

export const ModelContainer = styled.div`
  position: fixed;
  background-color: ${p => p.theme.colors.mainO};
  top: 0;
  left:0;

  right: 0;
  bottom: 0;
  ${flexCenter};
  padding: 0 1rem;
  z-index: 9999;
`;
export const Headings = styled.div`
  position: absolute;
  text-align: center;
  top: 15vh;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;

  ${p => p.theme.devices.md} {
    top: 10vh;
  }
`;
export const Heading = styled.h2`
  color: ${p => p.theme.colors.borderColor};
  font-size: 5rem;

  ${p => p.theme.devices.md} {
    font-size: 6rem;
  }
`;
export const SubHeading = styled.h3`
  color: ${p => p.theme.colors.borderColor};
  font-size: 2rem;

  ${p => p.theme.devices.md} {
    font-size: 4rem;
  }
`;
export const WaterMark = styled.div`
  display: ${p => p.theme.name === "dark" && "none"}; 
  position: absolute;
  top: 0;
  width: 250%;
  z-index: 5;

  ${p => p.theme.devices.md} {
    width: 100%;
  }

  svg {
    width: 100%;
    transform: translateY(-140px);

    circle {
      fill: ${p => p.theme.colors.text.main};
      opacity: 0.1;
    }
  }
`;
export const Box = styled.div`
  border: 1px solid ${p => p.theme.colors.borderColor};
  border-radius: 1rem;
  overflow: hidden;
  position: absolute;
  background: ${p => p.theme.colors.main};
  opacity :1;
  z-index: 6;
  width: 90%;
  max-width: 470px;

  ${p => p.theme.devices.lg} {
    max-width: ${p => p.big ? "600px" : "500px"};
  }

  /* ${p => p.theme.devices.xl} {
    max-width: 550px;
  }

  ${p => p.theme.devices.xxl} {
    max-width: 600px;
  } */
`;
export const ModalHeading = styled.h2`
`;
export const ModalHeader = styled.div`
    ${flexBetween};
    padding: 1rem 2rem;
    background: ${p => p.theme.gradient.violet};

    h2 {
      color: ${p => p.theme.colors.white};
    }
`;
export const Button = styled.button`
  ${Btn};
  border: none;
`;
export const ModalContent = styled.div`
  padding : 1rem 2rem 1rem;
  margin: auto;
  max-width: 300px;
`;
