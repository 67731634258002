import { useEffect } from "react";

const useOutsideBodyClick = (ref, callBack) => {

  const handleClick = (e) => {
    if(ref.current && !ref.current.contains(e.target)) {
      callBack();
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
}

export default useOutsideBodyClick;