import { createSlice } from '@reduxjs/toolkit'

export const dappSlice = createSlice({
  name: 'dapp',
  initialState: {
    theme: "light",
    web3Instance: {},
    isLoggedIn: false,
    walletAddress: [],
    provider: {},
    netId: null,
    transactions: [],
    etherBalance: null,
    termsAndCondition: false
  },
  reducers: {
    setTermsAndCondition: (state, action) => { state.termsAndCondition = action.payload },
    changeTheme: (state, action) => { state.theme = action.payload.theme },
    setWeb3: (state, action) => { state.web3Instance = action.payload; },
    login: (state, action) => { state.isLoggedIn = true; },
    logout: (state, action) => { state.isLoggedIn = false; },
    setWalletAddress: (state, action) => { state.walletAddress = action.payload },
    setProvider: (state, action) => { state.provider = action.payload },
    setNetworkId: (state, action) => { state.netId = action.payload.netId; },
    setTransactions: (state, action) => { state.transactions = action.payload.transactions },
    setEtherBalance: (state, action) => { state.etherBalance = action.payload.etherBalance }
  },
})

// Action creators are generated for each case reducer function
export const { setWeb3, setEtherBalance, changeTheme, setTransactions, setNetworkId, login,
  logout, setWalletAddress, setProvider, setTermsAndCondition } = dappSlice.actions

export default dappSlice.reducer