import { useEffect } from "react";

const useOutsideClick = (ref, wrapperRef, callBack) => {

  const handleClick = (e) => {
    if(ref.current && !ref.current.contains(e.target)) callBack()
  }

  useEffect(() => {
    if(wrapperRef && wrapperRef.current) {
      const wrapperRefCopy = wrapperRef.current;
      wrapperRefCopy?.addEventListener("click", handleClick);
  
      return () => {
        wrapperRefCopy?.removeEventListener("click", handleClick);
      };
    }
  });
}

export default useOutsideClick;