import React, { useEffect, useRef } from 'react'
import pug from 'pug'
import { useState } from 'react';
import { Container, Dropdown , Button} from "./languageDropdownStyle"
import useOutsideBodyClick from '../../hooks/useOutsideBodyClick';
import { ReactSVG } from 'react-svg';
import ChevronSVG from "../../assets/chevron.svg"
import { useTranslation } from 'react-i18next'
import { LANGUAGE } from '../../constants/appConstants';

const LanguageDropdown = () => {

  const { i18n } = useTranslation();

  const [selectedLang, setSelectedLang] = useState({});
  const [languagesList,setLanguagesList] = useState(
    [
      {
        label: "EN",
        img: "/images/eng-flag.png",
        disabled: false,
        inactive: false,
        name: LANGUAGE.english,
        clickHandler: () => changeLang(LANGUAGE.english)
      },
      {
        label: "DE",
        img: "/images/deu-flag.png",
        disabled: false,
        inactive: false,
        name: LANGUAGE.german,
        clickHandler: () => changeLang(LANGUAGE.german)
      },
      {
        label: "ES",
        img: "/images/spa-flag.png",
        disabled: false,
        inactive: false,
        name: LANGUAGE.spanish,
        clickHandler: () => changeLang(LANGUAGE.spanish)
      },
      {
        label: "CN",
        img: "/images/chinese.png",
        disabled: false,
        inactive: false,
        name: LANGUAGE.chinese,
        clickHandler: () => changeLang(LANGUAGE.chinese)
      },
      {
        label: "TR",
        img: "/images/turkish.png",
        disabled: false,
        inactive: false,
        name: LANGUAGE.turkish,
        clickHandler: () => changeLang(LANGUAGE.turkish)
      },
    ]
  );

  const setSelectedLanguage = (language) => {
    let data;
    languagesList.forEach(lang => {
      if(lang.name === language) data = lang
    })
    data ? setSelectedLang(data) : setSelectedLang(languagesList[0])
  }

  const changeLang = (language) => {
    i18n.changeLanguage(language);
    language && setLanguagesList(() =>  
      languagesList.map(lang => 
          lang.name === language ? 
          ({ ...lang, disabled: true, inactive: true }) 
          :
          ({ ...lang, disabled: false, inactive: false })
      )
    );
    language && setSelectedLanguage(language)
  }

  useEffect(() => {
    const language = localStorage.getItem("i18nextLng")
    language && setLanguagesList(() =>  
      languagesList.map(lang => 
          lang.name === language ? 
          ({ ...lang, disabled: true, inactive: true }) 
          :
          ({ ...lang, disabled: false, inactive: false })
      )
    );
    language && setSelectedLanguage(language)
  },[])

  const [show, setShow] = useState(false);
  const dropdownRef = useRef(null)

  useOutsideBodyClick(dropdownRef, () => {
    show && setShow(false)
  });

  const handleToggleDropdown = () => {
    setShow(!show)
  }
  
  const dropdownStyle = {
    display: `${show ? "block" : "none"}`
  }
  
  return (
    pug`
      Container(onClick=handleToggleDropdown ref=dropdownRef)
        div(className="img-btn")
          img(src=selectedLang.img)

        Dropdown(style=dropdownStyle)
          if languagesList
            each lang in languagesList
              Button(key=lang.label disabled=lang.disabled inactive=lang.inactive onClick=lang.clickHandler)
                p #{lang.label}

                ReactSVG(src=ChevronSVG className="chevron")

                div(className="img-btn")
                  img(src=lang.img)
        `
  )
}

export default LanguageDropdown
