import styled from 'styled-components'
import { PoppinsSemiBold } from '../../styled/mixins';

export const Wrapper = styled.section`
    background: ${p => p.theme.colors.main};
    padding: 1.5rem;
    border-radius: 10px;
    box-shadow: 0px 10px 30px #0000001A;

    ${p => p.theme.devices.md} {
        padding: 2rem;
    }
`;
export const Header = styled.div`
    text-align: right;
    margin-bottom: 1rem;    
`;
export const Container = styled.div`
    width: 100%;
    max-width: 380px;
    margin: auto;

    /* ${p => p.theme.devices.md} {
        max-width: 420px;
    }

    ${p => p.theme.devices.lg} {
        max-width: 450px;
    } */
`;
export const CloseLogo = styled.div`
    cursor: pointer;
    width: 20px;
    margin-left: auto;

    svg {
        width: 100%;
        height: 100%;
    }

    /* ${p => p.theme.devices.lg} {
        width: 25px;
    }

    ${p => p.theme.devices.xl} {
        width: 30px;
    }
    ${p => p.theme.devices.xxl} {
        width: 35px;
    } */
`;
export const Headings = styled.div`
  text-align: center;
`;
export const Heading = styled.h2`
  font-size: 2rem;
  margin-bottom: 1rem;
  ${PoppinsSemiBold};
  color: ${p => p.theme.colors.text.main};

  /* ${p => p.theme.devices.loadingMsg} {
      font-size: 2.1rem;
  }
  ${p => p.theme.devices.xl} {
      font-size: 2.5rem;
  }
  ${p => p.theme.devices.xxl} {
      font-size: 2.8rem;
  } */
`;
export const SubHeading = styled.h3`
  font-size: 1.3rem;
  margin-bottom: 10px;
  ${PoppinsSemiBold};
  color: ${p => p.theme.colors.text.main};

  /* ${p => p.theme.devices.loadingMsg} {
      font-size: 1.5rem;
  }
  ${p => p.theme.devices.xl} {
      font-size: 1.8rem;
  }
  ${p => p.theme.devices.xxl} {
      font-size: 2rem;
  } */
`;