import styled, { css } from "styled-components"
import { flexBetween, flexCenter, linksFill, linksFillH, linksStroke, linksStrokeH } from "../../styled/mixins";
import { TransparentBtn, TransparentLink } from '../../styled/buttons';
import { Link } from "react-router-dom"

export const DesktopFlexDiv = styled.div`
    display: none;
    align-items: center;

    .back-btn {
        transform: translateX(2px);
    }

    &>div {
        border-right: 1px solid ${p => p.theme.colors.borderColor};
        padding: 0 ${p => p.theme.spacings.xs};

        &>div , &>button , &>a {
            margin: 0 ${p => p.theme.spacings.xs};
        }

        &:last-child {
            border-right: none;
        }
    }

    a, p {
        color: ${p => p.theme.colors.text.links};
        font-family: ${p => p.theme.fontF.poppins};
        font-weight: ${p => p.theme.fontWeight.medium};
        margin-left: ${p => p.theme.spacings.xs};
    }

    ${p => p.theme.devices.lg} {
        display: flex;
    }

    ${p => p.theme.devices.xl} {
        a, p {
            margin-left: ${p => p.theme.spacings.sm};
        }
    }

    /* ${p => p.theme.devices.xxl} {
        a, p {
            margin-left: ${p => p.theme.spacings.md};
        }
    } */
    
`;
export const MobileFlexDiv = styled.div`
    display: flex;
    align-items: center;

    &>div {
        margin: 0 5px;
    }

    ${p => p.theme.devices.lg} {
        display: none;
    }
`;
export const HeaderC = styled.header`
`;
export const TopBottomCommon = css`
    .svg-btn , .img-btn {
        outline: none;
        border-radius: 50%;
        background: transparent;
        border: none;
        width: ${p => p.theme.circleIcon.size};
        height: ${p => p.theme.circleIcon.size};
        cursor: pointer;
        border: 1px solid ${p => p.theme.colors.borderColor};

        &>div {
            width: 100%;
            height: 100%;

            &>div {
                width: 100%;
                height: 100%;
                ${flexCenter};
            }
        }

        svg {
            border: none;
            width: 40%;
            height: 40%;
        }

        .icon {
            padding: 5px;
            color: ${p => p.theme.colors.text.links};
            font-size: 2.5rem;
        }

        &:hover .icon {
            border-color: ${p => p.theme.colors.green};
        }

        &:focus .icon {
            color: ${p => p.theme.colors.green} !important;
        }
    }

    /* ${p => p.theme.devices.xl} {
        .img-btn, .svg-btn {
            width: calc(${p => p.theme.circleIcon.size} + 10px);
            height: calc(${p => p.theme.circleIcon.size} + 10px);
        }
    }

    ${p => p.theme.devices.xxl} {
        .img-btn, .svg-btn {
            width: calc(${p => p.theme.circleIcon.size} + 20px);
            height: calc(${p => p.theme.circleIcon.size} + 20px);
        }
    } */
`;
export const Top = styled.div`
    ${flexBetween};
    align-items: flex-start;
    padding: 1rem;

    ${TransparentBtn}, ${TransparentLink} {
        ${linksStrokeH};
        .links-stroke {
            ${linksStroke};
        }

        ${linksFillH};
        .links-fill {
            ${linksFill};
        }
    }

    ${TopBottomCommon};

    ${p => p.theme.devices.md} {
        padding: 1rem 2rem;
    }

    ${p => p.theme.devices.xl} {
        align-items: center;
    }

    /* ${p => p.theme.devices.xxl} {
        padding: 2rem 3rem;
    } */
`;
export const AddressBalanceC = styled.div`
    ${flexCenter};
    justify-content: space-evenly;
    display: ${p => p.tablet && "none"};
    ${TopBottomCommon};

    &>button {
        margin: 0 5px;
    }

    ${p => p.theme.devices.md} {
        display: ${p => p.tablet ? "flex" : "none"};
    }
`;
export const Logo = styled(Link)`
    display: inline-block;
    transform: translateY(2px);
    max-width: 180px;

    svg {
        width: 100%;
    }
    /* this is svg selectors for styling */
    .logo path , .logo-circle {
        fill: ${p => p.theme.colors.text.main};
    }

    /* ${p => p.theme.devices.xl} {
        max-width: 250px;
    } */

    /* ${p => p.theme.devices.xxl} {
        max-width: 300px;
    } */
`;
export const FlexCenterDiv = styled.div`
    ${flexCenter};
`;
