import React, { useEffect } from 'react';
import pug from "pug"
import { useDispatch, useSelector } from 'react-redux';
import { getLocalStorage, setLocalStorage } from './common/helpers/localStorage';
import { createProvider, disconnectWallet,  getContract } from './common/common';
import {  NETWORKS, STORAGE, WALLETS } from './constants/appConstants';
import styled, { css } from "styled-components"
import Web3 from "web3";
import 'react-toastify/dist/ReactToastify.css';
import BigNumber from 'bignumber.js';
import Footer from "./partials/footer"
import TermsAndCondition from "./components/termsAndCondition"
import Modal from './components/modals/Modal'

// Component
import Routes from "./routes/Routes"
import Header from './partials/header/Header';

// actions
import { changeTheme, setEtherBalance, setLocalLang, setWalletAddress, setWeb3 } from "./redux/slices/dappSlice"
import { setBalance } from './redux/slices/exchangeSlice';
import { flexCenter } from './styled/mixins';
import { closeTermsAndConditionModal } from './redux/slices/basicFeaturesSlice';


require('dotenv').config();

const App = () => {

  const dispatch = useDispatch();

  const walletAddress = useSelector(state => state.dapp.walletAddress);
  const web3Instance = useSelector(state => state.dapp.web3Instance);
  const tokens = useSelector(state => state.exchange.tokens);
  const { termsAndConditionModal } = useSelector(({features}) => features);

  const closeTermsAndCondition = () => dispatch(closeTermsAndConditionModal())
  
  // if page  refresh then set the web3 provider according to previously connected wallet connection
  useEffect(() => {
    const storeWeb3Instance = async () => {
      try {
        let web3Instance;
        /*
         creating web3 instance 
         storing into redux store
        */
        const walletType = await getLocalStorage(WALLETS.walletType);
        const walletSession = JSON.parse(localStorage.getItem("walletconnect"));

        if (walletType) {
          const provider = await createProvider(walletType, walletSession);
          // create web3 instance and store
          web3Instance = new Web3(provider);
          dispatch(setWeb3(web3Instance));
        } else {
          web3Instance = new Web3(Web3.givenProvider);
          dispatch(setWeb3(web3Instance));
        }

        // check if accounts already exist in local storage
        // if yes then set in redux store
        const walletAddress = await getLocalStorage(STORAGE.walletAddress);
        walletAddress && dispatch(setWalletAddress(walletAddress));

      } catch (error) {
        console.log('error')
        console.log(error)
      }
    }
    storeWeb3Instance();
  }, [dispatch]);

  // check which network type is being used
  // set accounts if account changed
  useEffect(() => {
    (async () => {
      try {
        const walletType = await getLocalStorage(WALLETS.walletType)
        if (window.ethereum !== undefined) {
          if (walletType === WALLETS.metamask || walletType === WALLETS.trustWallet) {
            window.ethereum.on('accountsChanged', async function (accounts) {
              if (accounts.length) {
                dispatch(setWalletAddress(accounts));
                setLocalStorage(STORAGE.walletAddress, accounts);
              } else disconnectWallet(dispatch)
            });
          }

          window.ethereum.on("chainChanged", async (chainId) => {
            if (chainId !== NETWORKS.mainnetId) disconnectWallet(dispatch)
          });
        }
      } catch (error) {
        console.log(error)
      }
    })()
  });

  // if wallet address changes then get the new balance
  useEffect(() => {
    (async () => {
      try {
        if (web3Instance && walletAddress.length) {
          const setBal = async (symbol) => {
            try {
              const contract = await getContract(web3Instance, symbol);
              // getting balance of token
              let balance = await contract.methods.balanceOf(walletAddress[0]).call();
              const decimals = await contract.methods.decimals().call();
              balance = new BigNumber(balance).div(new BigNumber(10).pow(decimals)).toString();
              dispatch(setBalance({ balance, symbol }));
            } catch (error) {
              console.log(error)
            }
          }

          // getting balance of all tokens
          tokens.forEach(token => setBal(token.name));

          // getting ehther balance
          let etherBalance = await web3Instance.eth.getBalance(walletAddress[0]);
          etherBalance = (etherBalance / 10 ** 18 || 0).toFixed(5);
          dispatch(setEtherBalance({ etherBalance }))
        }
      } catch (error) {
        console.log(error)
      }
    })();
  }, [walletAddress]);

  // theme switcher
  useEffect(() => {
    (async () => {
      const prevTheme = await getLocalStorage(STORAGE.themeMode);
      prevTheme && dispatch(changeTheme({theme: prevTheme}));
    })()
  },[dispatch]);

  return (
    pug`
      Container
        Modal(big=true open=termsAndConditionModal handleClose=closeTermsAndCondition)
          TermsAndCondition

        Watermark

        Header

        Main
          Routes

        FooterC
          Footer
    `
  )
}

export default React.memo(App);

// styled components for appcloseTermsAndConditionModal
const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
`;
const Watermark = styled.div`
  position: absolute;
  top: 0;
  left :0;
  width: 100vw; 
  height: 60vh;
  background-image: url("/images/modal-watermark.svg");
  background-color: ${p => p.theme.colors.main};
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 250%;
  opacity: 1;
  opacity: 0.02;
  z-index: -1;

  ${p => p.theme.devices.md} {
    width: 98vw; 
    margin: auto;
    background-size: 100%;
  }
`;
const maxWidth = css`
  width: 100%;
  max-width: 1400px;
  margin: auto;
`;
const Main = styled.div`
  flex: 1;
  padding: 2rem 0;
  ${maxWidth};
  ${flexCenter};

  ${p => p.theme.devices.md} {
    padding: 2rem 4rem;
  }
`;
const FooterC = styled.section`
  ${maxWidth};
`;