import styled from "styled-components"
import { flexCenter, linksFill, linksFillH, linksStroke, linksStrokeH } from "../../../../styled/mixins";
import { TransparentBtn } from "../../../../styled/buttons"

export const ConnectionOptions = styled.div`
  ${flexCenter};
  gap : 10px;
  justify-content: space-between;

  &>div, &>button {
    border: 1px solid ${p => p.theme.colors.borderColor};
  }
  ${TransparentBtn} {
    flex: 1;
    padding: 10px 0;
    border-radius: 20px;

    ${linksStrokeH};
    .links-stroke {
        ${linksStroke};
    }

    ${linksFillH};
    .links-fill {
        ${linksFill};
    }

    p {
        color: ${p => p.theme.name === "dark" && "white"};
    }

    svg {
        margin-left: 10px;
    }
  }

  /* ${p => p.theme.devices.lg} {
    ${TransparentBtn} {
        padding: 15px 0;
        border-radius: 30px;
    }
    p {
        font-size: 1.1rem;
    }
  }

  ${p => p.theme.devices.xl} {
    ${TransparentBtn} {
        padding: 17px 10px;
        border-radius: 35px;
    }
    p {
        font-size: 1.3rem;
    }
    } */

    /* ${p => p.theme.devices.xxl} {
        ${TransparentBtn} {
        padding: 20px 10px;
        border-radius: 40px;
    }
    p {
        font-size: 1.5rem;
    }
    } */
`;
export const Address = styled.h4`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: lighter;
`;
export const Icon = styled.button`
    background: transparent;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    ${flexCenter};

    .svg-div {
        width: 40%;
        height: 40%;
        ${flexCenter};

        &>div {
            width: 100%;
            height: 100%;
        }
    }

    svg {
        width: 100%;
        height: 100%;
    }

    a {
        width: 100%;
        height: 100%;
        ${flexCenter};

        ${linksStrokeH};
        .links-stroke {
            ${linksStroke};
        }
    }

    ${linksStrokeH};
    .links-stroke {
        ${linksStroke};
    }

    /* ${p => p.theme.devices.lg} {
        width: 50px;
        height: 50px;
    }

    ${p => p.theme.devices.xl} {
        width: 60px;
        height: 60px;
    } */
`;
export const CopiedTitle = styled.p`
    position: absolute;
    top: 115%;
    left: 50%;
    transform: translateX(-50%);
    background: ${p => p.theme.colors.main};
    color: ${p => p.theme.colors.text.main};
    padding: 5px;
    text-transform: uppercase;
    font-size: 0.9rem;
    box-shadow: ${p => p.theme.shadows.one};
    border-radius: 5px;

    &::before {
        content: "";
        border: 5px solid ${p => p.theme.colors.main};
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid transparent;
    }

`;