import styled from "styled-components";
import { Btn, PoppinsRegular, scrollbar } from "../../styled/mixins";

export const Container = styled.div`
  padding: 1rem;

  ${p => p.theme.devices.lg} {
    padding: 2rem;
  }
`;
export const Header = styled.div``;
export const CloseArea = styled.div`
  text-align: right;
  width: 100%;

  &>div {
    display: inline-block;
    cursor: pointer;
  }

  svg {
    width: 100%;
    height: 100%;
  }

  ${p => p.theme.devices.lg} {
    &>div {
      width: 20px;
    }
  }
`;
export const Heading = styled.h1`
  text-align: center;
  color: ${p => p.theme.colors.green};
  padding: 1rem 0;
`;
export const Content = styled.div`
  color: ${p => p.theme.colors.text.main};
  max-height: 60vh;
  max-width: 100%;
  overflow-y: auto;
  ${scrollbar};
  ${PoppinsRegular};
  padding-right: 1rem;
  overflow-wrap: break-word;

  .link-tag {
    color: ${p => p.theme.colors.text.main};
    font-weight: bold;

    &:hover {
      color: ${p => p.theme.colors.green};
    }
  }
`;
export const SubHeading = styled.h2`
  font-size: 1.3rem;
  margin-bottom: 1rem;
`;
export const Text = styled.p`
  font-size: 1rem;
  margin-bottom: 1rem;
`;
export const SubHeadingTwo = styled.h3`
  margin-bottom: 1rem;
  font-size: 1.2rem;
`;
export const TextGroup = styled.div`
  margin-bottom: 1rem;
  ${Text} {
    display: inline;
  }
`;
export const GroupHeading = styled.h4`
  font-size: 1.1rem;
  display: inline;
`;
export const Footer = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding-top: 1rem;
`;
export const Button = styled.button`
  ${Btn};
  font-size: 0.9rem;
  padding: 10px 18px;

  ${p => p.theme.devices.lg} {
    padding: 10px 32px;
    font-size: 1rem;
  }
`;