import styled from "styled-components"
import { Btn, flexCenter } from "../../styled/mixins";

export const Wrapper = styled.section`
  ${flexCenter};
  background-color: ${p => p.theme.colors.main};
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
`;
export const Container = styled.section`
  ${flexCenter};
  flex-direction: column;
  color:  ${p => p.theme.colors.text.main};

  h2 {
    font-size: 9rem;
  }
  p {
    font-size: 2rem;
    text-transform: capitalize;
  }
`;
export const Button = styled.section`
  ${Btn};
  margin-top: 2rem;
`;