import React from 'react'
import pug from "pug"
import PropTypes from "prop-types"
import { Container, TransactionContent, TransactionStatus } from "./SingleTransactionStyle"
import CheckIcon from '@mui/icons-material/Check';
import TimesSVG from "../../../../assets/times.svg"
import { ReactSVG } from 'react-svg';

export const SingleTransaction = (props) => {
  return (
    pug`
      Container
        TransactionContent #{props.data.hash}
        TransactionStatus
          if props.data['txreceipt_status']==='1'
            CheckIcon

          else
            ReactSVG(src=TimesSVG)
    `
  )
}

SingleTransaction.propTypes = {
  data: PropTypes.object
}