const colors = {
    blue1: "#8b8bff",
    blue2: "#3f3f9f",
    blue3: "#080852",
    blue4: "#00004c",

    dark1: "#b5b6b6",
    dark2: "#707070",
    dark3: "#222222",
    black: "#000000",

    white: "#ffffff",
    light2: "#fbfcfc",

    gray1: "#8F8F8F",
    gray2: "#ebebeb",

    green: "#0be35f",
    yellow: "#ffdd00",
    red: "#ff0000",
}

const devices = {
    xs: "@media screen and (min-width: 350px)",
    sm: "@media screen and (min-width: 550px)",
    md: "@media screen and (min-width: 768px)",
    lg: "@media screen and (min-width: 960px)",
    xl: "@media screen and (min-width: 1200px)",
    xxl: "@media screen and (min-width: 1500px)"
}

const commonThemeValues = {
    radius: {
        r1: "1rem",
        r2: "1.5rem"
    },
    helpLogo: {
        fontSize: "2rem"
    },
    fontF: {
        montserrat: "Montserrat, sans-serif",
        poppins: 'Poppins, sans-serif',
        roboto: 'Roboto, sans-serif'
    },
    fontWeight: {
        regular: 400,
        medium: 500,
        semiBold: 600,
        bold: 700
    },
    spacings: {
        xs: "5px",
        sm: "10px",
        md: "15px",
        lg: "20px"
    },
    shadows: {
        one: "rgba(0, 0, 0, 0.24) 0px 3px 8px"
    },
    gradient: {
        violet: "linear-gradient(45deg, rgb(122, 110, 170), transparent)",
    },

    typography: {
        title1: '2.4rem',
        title2: '2.8rem',
        title3: '3.2rem',
        title4: '3.6rem',
        title5: '4.2rem',
        title6: '5.2rem',
        subTitle1: '2rem',
        subTitle2: '2.2rem',
        subTitle3: '2.4rem',
        subTitle4: '2.6rem',
        text1: '1.2rem',
        text2: '1.4rem',
        text3: '1.6rem',
        subText1: '1rem',
        subText2: '1.1rem',
        subText3: '1.2rem',
        subText4: '1.3rem',
        subText5: '1.4rem',
      },
}

const circleIcon = {
    size: '37px',
}

export const lightMode = {
    ...commonThemeValues,
    devices,
    name: "light",
    colors: {
        ...colors,
        button: {
            background: "#00004c",
            text: colors.white,
        },
        text: {
            main: "#00004c",
            links: "#707070",
            content: colors.black,
            instruction: "#00004c",
            transaction: colors.dark3,
            loadingMsg: colors.dark3,
            footer: "#b5b6b6"
        },
        reverse: {
            black: colors.black,
            white: colors.white
        },

        borderColor: "#ebebeb",
        hoverBorderColor: colors.green,
        main: "#fff",
        mainO: "rgba(255 ,255 ,255 ,0.9)",
        lightMain: colors.white,
    },
    circleIcon
}

export const darkMode = {
    ...commonThemeValues,
    devices,
    name: "dark",
    colors: {
        ...colors,
        text: {
            links: colors.white,
            main: colors.white,
            content: "#3f3f9f",
            transaction: colors.white,
            loadingMsg: colors.green,
            instruction: colors.green,
            footer: "#3f3f9f"
        },
        button: {
            background: colors.green,
            text: colors.white,
        },
        reverse: {
            black: colors.white,
            white: colors.black
        },

        lightMain: "#00004c",
        main: "#00004c",
        mainO: "rgba(0,0,76,0.97)",

        borderColor: "#3f3f9f",
        hoverBorderColor: colors.green,
        lightBlue: "#97b9de",
    },
    circleIcon
}