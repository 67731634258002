import styled , {css } from "styled-components"
import { flexCenter, linksFill, linksFillH, linksStroke, linksStrokeH } from "./mixins";
import {Link} from 'react-router-dom'

const transparentCommonCss = css`
  background-color:transparent;
  border: none;
  outline: none;
  padding: ${p => p.pad && "0 10px"};
  ${flexCenter};
  border: ${p => p.border && `1px solid ${p.theme.colors.borderColor}`};
  border-radius: ${p => p.border && "20px"};
  cursor: pointer;

  svg, .img-btn {
    border: ${p => p.border && "none !important"};
  }

  img { 
    width: 35px;
    object-fit: contain;
  }

  p { font-size: 1rem; }

  /* ${p => p.theme.devices.lg} {
    p { font-size: 1.1rem; }
  } */

  /* ${p => p.theme.devices.xl} {
    p { font-size: 1.1rem; }
    border-radius:  ${p => p.border && "40px"};
  }

  ${p => p.theme.devices.xxl} {
    p {
      font-size: 1.3rem;
    }
    border-radius:  ${p => p.border && "60px"};
  } */
`;
export const TransparentBtn = styled.button`
  ${transparentCommonCss};

  ${linksStrokeH};
  .links-stroke {
      ${linksStroke};
  }

  ${linksFillH};
  .links-fill {
      ${linksFill};
  }

  p {
    color: ${p => p.links && p.theme.colors.text.links};
  }

  svg ,img { 
    ${flexCenter};
  }
`;
export const TransparentLink = styled(Link)`
  ${transparentCommonCss};

  svg ,img { 
    ${flexCenter};
    border-radius: 50%;
  }
`;