export const setLocalStorage = async (key, value) => {
    try {
        const stringifyVal = JSON.stringify(value);
        localStorage.setItem(key, stringifyVal);
        return true;
    } catch (error) {
        return false;
    }
};

export const getLocalStorage = async (key) => {
    try {
        const parseVal = JSON.parse(localStorage.getItem(key));
        return parseVal ? parseVal : false
    } catch (error) {
        return false;
    }
};

export const deleteLocalStorage = async (key) => {
    try {
        localStorage.removeItem(key)
        return true
    } catch (error) {
        return false
    }
};