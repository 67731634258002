import React from 'react';
import pug from 'pug';
import { Wrapper, Container, Button } from "./notFoundStyle"
import {useHistory} from "react-router-dom"

export function NotFound() {
  const history = useHistory();

  return (
    pug`
      Wrapper
        Container
          h2 404

          p page not found

          Button(onClick=()=>history.goBack()) Go Back
    `
  )
}