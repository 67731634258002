import React, { memo, useRef, useState } from 'react'
import pug from "pug"
import { ConnectionOptions, Icon,CopiedTitle} from './walletDetailsStyle'
import PropTypes from "prop-types"
//mui icons
import { useDispatch, useSelector } from 'react-redux';
import { copyToClipboardByRef, disconnectWallet } from '../../../../common/common';

//svg
import {ReactSVG} from 'react-svg'
import AddressSVG from "../../../../assets/balance.svg"
import CopySVG from "../../../../assets/copy.svg"
import DisconnectSVG from "../../../../assets/disconnect.svg"
import ViewExplorerSVG from "../../../../assets/view-explorer.svg"
import { TransparentBtn } from "../../../../styled/buttons";


export const WalletDetails = memo((props) => {
  const dispatch = useDispatch();
  const walletAddress = useSelector(state => state.dapp.walletAddress);

  /**
   * copying address methods and state
   */
  const [copied, setCopied] = useState(false);
  const addressRef = useRef(null);

  // copy to clipboard
  const handleCopyToClipboard = async () => {
    try {
      const copied = await copyToClipboardByRef(addressRef);
      copied && setCopied(true);
      setTimeout(()=>setCopied(false),1000);
    } catch (error) {
      console.log(error)
    }
  }

  const handleDisconnect = () => {
    disconnectWallet(dispatch);
    props.handleClose();
  }

  return (
    pug`
      div
        ConnectionOptions
          TransparentBtn
            p #{walletAddress[0] && walletAddress[0].substring(0,5)}...#{walletAddress[0] && walletAddress[0].substring(walletAddress[0].length - 4)}
            div(className="img-btn")
              ReactSVG(src=AddressSVG)

          Icon(onClick=handleCopyToClipboard)
            ReactSVG(className="svg-div" src=CopySVG)
            
            if copied
              CopiedTitle Copied

          Icon
            a(href="https://etherscan.io/address/"+walletAddress[0] target="_blank")
              ReactSVG(className="svg-div" src=ViewExplorerSVG)

          Icon(onClick=handleDisconnect)
            ReactSVG(className="svg-div" src=DisconnectSVG)
    `
  )
})

WalletDetails.propTypes = {
  handleClose : PropTypes.func
}