import styled from 'styled-components'
import { Btn, PoppinsSemiBold, scrollbar } from '../../../../styled/mixins';

export const ConnectionTitle = styled.h3`
  font-size: 1.2rem;
  ${PoppinsSemiBold};
  letter-spacing: -0.06px;
  color: ${p => p.theme.colors.text.main};

  ${p => p.theme.devices.lg} {
    font-size: 1.3rem;
  }

  ${p => p.theme.devices.xl} {
    font-size: 1.5rem;
  }

  ${p => p.theme.devices.xxl} {
    font-size: 1.7rem;
  }
`;
export const Container = styled.div`
  margin: 2rem 0;
`
export const AllTransactions = styled.div`
  position: relative;
  min-height: 70px;
  margin: 0.5rem 0;
  max-height: 250px;
  overflow-y: auto;
  ${scrollbar};
  margin-bottom: 1rem;

  ${p => p.theme.devices.xl} {
    min-height: 100px;
    max-height: 300px;
  }

  ${p => p.theme.devices.xxl} {
    min-height: 100px;
    max-height: 385px;
  }
`;
export const Transaction = styled.p``;
export const BtnContainer = styled.div`
  text-align: center;
`;
export const ViewMore = styled.button`
  ${Btn};
`;
export const Msg = styled.h5`
  text-align: center;
  color: ${p => p.theme.colors.green};
  font-size: 1.2rem;
  padding-top: 1rem;

  ${p => p.theme.devices.lg} {
    font-size: 1.3rem;
  }

  ${p => p.theme.devices.xl} {
    font-size: 1.5rem;
  }

  ${p => p.theme.devices.xxl} {
    font-size: 1.6rem;
  }
`;