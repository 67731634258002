import styled from "styled-components"
import { flexCenter, rotate } from "../../styled/mixins";

export const Wrapper = styled.div`
  min-height: 50vh;
  ${flexCenter};
`;
export const Container = styled.div`
  /* svg {
    font-size: 3rem;
    animation: ${rotate} 0.5s infinite linear alternate-reverse;
  } */
`;