import styled from "styled-components"
import { flexBetween, flexCenter,  linksFillFooter, linksFillHFooter, linksStrokeFooter, linksStrokeHFooter } from "../../styled/mixins";

export const FooterC = styled.footer`
  padding: 2rem;
  border-top: 1px solid ${p => p.theme.colors.borderColor};
  line-height: 1.5;
  margin-top: 2rem;

  p, a, span {
    font-size: 0.9rem;
    color: ${p => p.theme.colors.text.footer};
  }

  a {
    display: block;
  }

  &>div {
    margin: 1rem 0;
    text-align: center;
  }

  &>div:nth-child(3), &>div:nth-child(2) {
    a, p {
      cursor: pointer;
    }
    a:hover , p:hover {
      color: ${p => p.theme.colors.green};
    }
  }

  ${p => p.theme.devices.md} {
    ${flexBetween};
    p, a, span {
      font-size: 1rem;
    }
  }

  ${p => p.theme.devices.lg} {
    &>div {
      &:first-child, &:last-child {
        text-align: left;
      }
    }
    /* p, a, span {
      font-size: 1.1rem;
    } */
  }

  /* ${p => p.theme.devices.xl} {
    p, a, span {
      font-size: 1.1rem;
    }
  }
  ${p => p.theme.devices.xxl} {
    p, a, span {
      font-size: 1.2rem;
    }
  } */
`;
export const IconsC = styled.div`
  ${flexCenter}; 

  &>div {
    margin: 5px;
  }

  &>a {
    margin: 5px;
    
    .links-stroke {
        ${linksStrokeFooter};
    }

    .links-fill {
        ${linksFillFooter};
    }
    ${linksStrokeHFooter};
    ${linksFillHFooter};

  }
`;