import React, { useRef } from 'react'
import pug from "pug";
import PropTypes from "prop-types"
import { ModelContainer, WaterMark, Box, Heading, SubHeading, Headings } from "./modalStyle"
import { ReactSVG } from 'react-svg';
import ModalWatermark from "../../../assets/modal-watermark.svg"
import useOutsideClick from '../../../hooks/useOutsideclick';

export const Model = ({ children:Children, big, open, handleClose, setLoadingErrors, falseAllLoading }) => {
  const ref = useRef(null)
  const wrapperRef = useRef(null)

  const modalStyle = {
    display: `${open ? "flex" : "none"}`
  }

  useOutsideClick(ref , wrapperRef, () => {
    setLoadingErrors && setLoadingErrors("");
    falseAllLoading && falseAllLoading();
    handleClose();
  });

  return (
    pug`
      ModelContainer(style=modalStyle ref=wrapperRef)
        Headings
          Heading Swap
          SubHeading CVN ERC20 to CVNX

        WaterMark
          ReactSVG(src=ModalWatermark)

        Box(ref=ref big=big) #{Children}
    `
  )
};

Model.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  children:  PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node
  ]).isRequired,
  falseAllLoading: PropTypes.func,
  setLoadingErrors: PropTypes.func,
  big: PropTypes.bool
};