import React from 'react'
import pug from "pug"
import { Wrapper , Container } from "./loaderStyle";
import PulseLoader from "react-spinners/PulseLoader";

// const override = css`
//   display: block;
//   margin: 0 auto;
//   border-color: red;
// `;

const Loader = () => {
  return (
    pug`
      Wrapper
        Container
          PulseLoader(color="#0be35f" loading=true)
    `
  )
}

export default Loader
