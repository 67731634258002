import React,{memo, useState} from 'react'
import pug from "pug";
import { FooterC, IconsC } from "./footerStyle"
import Telegram from "../../assets/telegram.svg";
import LinkedIn from "../../assets/in.svg";
import FB from "../../assets/fb.svg";
import Twitter from "../../assets/twitter.svg";
import Medium from "../../assets/medium.svg";
import {ReactSVG} from "react-svg"
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openTermsAndConditionModal } from '../../redux/slices/basicFeaturesSlice';

export const Footer = memo(() => {

  const {t} = useTranslation();
  const dispatch = useDispatch();

  const openTermsAndCondition = (event) => dispatch(openTermsAndConditionModal());

  const [socialLinks] = useState([
    {
      href: "https://t.me/crypviser_group",
      src: Telegram,
    },
    {
      href: "https://crypviser.medium.com",
      src: Medium,
    },
    {
      href: "https://facebook.com/crypviser",
      src: FB,
    },
    {
      href: "https://www.linkedin.com/company/crypviser",
      src: LinkedIn,
    },
    {
      href: "https://twitter.com/crypviser",
      src: Twitter,
    },
  ]);

  return (
    pug`
      FooterC
        div
          p #{t('Copyright')}

          p #{t('rights')}

          p #{t('address')}

        div
          a(href="mailto: cvcoin@crypviser.net") #{t('email')}

          IconsC
            each link in socialLinks
              a(key=link.href href=link.href target="_blank")
                ReactSVG(src=link.src)

        div
          a(href="#") #{t('Disclaimer')} 

          p(onClick=openTermsAndCondition) #{t('Terms & Conditions')} 

          a(href="#") #{t('WIKI')}
    `
  )
})
