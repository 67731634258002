import React, { memo, useEffect, useState } from 'react';
import pug from 'pug'
import LanguageDropdown from '../../components/languageDropdown/LanguageDropdown';
import { TransparentLink, TransparentBtn } from '../../styled/buttons';
import { Logo, HeaderC, DesktopFlexDiv ,FlexCenterDiv, MobileFlexDiv , Top, AddressBalanceC } from './headerStyle';
import { useDispatch, useSelector,  } from 'react-redux';
import { useLocation, useHistory } from "react-router-dom"
import DrawerComponent from '../../components/drawer/DrawerComponent';
import { setLocalStorage } from "../../common/helpers/localStorage"
import { STORAGE, THEMES } from '../../constants/appConstants';
import { changeTheme } from '../../redux/slices/dappSlice';
import  PropTypes  from "prop-types"
import Modal from "../../components/modals/Modal"
import AccountDetails from "../../components/accountDetails"
import { useTranslation } from 'react-i18next'

//svgs
import {ReactSVG} from 'react-svg'
import LogoSVG from "../../assets/logo.svg"
import BackSVG from "../../assets/back.svg"
import MenuBar from "../../assets/menubar.svg"
import SwapSVG from "../../assets/swap.svg"
import StakingSVG from "../../assets/staking.svg"
import VotingSVG from "../../assets/voting.svg"
import MoonSVG from "../../assets/moon.svg"
import SunSVG from "../../assets/sun.svg"
import HelpSVG from "../../assets/help.svg"
import AddressSVG from "../../assets/balance.svg"
import BalanceSVG from "../../assets/address.svg"

const BalanceBtn = memo((props) => {
  return (
    pug`
      TransparentBtn(border pad links)
        p #{props.tokens.find(token => token.name === props.fromToken).balance} CVN ERC20
        div(className="img-btn")
          ReactSVG(src=BalanceSVG)
    `
  )
})

BalanceBtn.propTypes = {
  tokens: PropTypes.array,
  fromToken: PropTypes.string,
}

const AddressBtn = memo((props) => {
  return (
    pug`
      TransparentBtn(border pad onClick=props.handleShowAccountDetails links)
        p(id="links-color") #{props.walletAddress[0].substring(0,5)}...#{props.walletAddress[0].substring(props.walletAddress[0].length - 4)}
        div(className="img-btn")
          ReactSVG(src=AddressSVG)
    `
  )
})

AddressBtn.propTypes = {
  handleShowAccountDetails: PropTypes.func,
  walletAddress: PropTypes.array,
}

const BackButton = memo((props) => {
  return (
    pug`
      TransparentBtn(onClick=()=>props.history.goBack())
        div(className="img-btn" )
          ReactSVG(className="back-btn" src=BackSVG)
          
        if props.title
          p #{props.title}
    `
  )
})

BackButton.propTypes = {
  title: PropTypes.string,
  history: PropTypes.object
}

const Header = (props) => {
  const dispatch = useDispatch();
  
  const [drawer, setDrawer] = useState(false);
  const handleShowDrawer = () => setDrawer(true)
  const handleCloseDrawer = () => setDrawer(false)

  const [showBackBtn, setShowBackBtn] = useState(null);

  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation()

  // state and methods for changing token type
  const [showAccountDetails, setShowAccountDetails] = useState(false);
  const handleShowAccountDetails = () => setShowAccountDetails(true);
  const handleCloseAccountDetails = () => setShowAccountDetails(false);

  const walletAddress = useSelector(({dapp}) => dapp.walletAddress);
  const tokens = useSelector(state => state.exchange.tokens);
  const fromToken = useSelector(state => state.exchange.fromToken);

  const switchDarkMode = () => {
    setLocalStorage(STORAGE.themeMode, THEMES.dark);
    dispatch(changeTheme({theme: THEMES.dark}))
  }

  const switchLightMode = () => {
    setLocalStorage(STORAGE.themeMode, THEMES.light);
    dispatch(changeTheme({theme: THEMES.light}))
  }

  useEffect(() => {
    location.pathname.split("/")[1] === "" ? setShowBackBtn(false) : setShowBackBtn(true)
  },[location]);

  return (
    pug`
      HeaderC
        Modal(open=showAccountDetails handleClose=handleCloseAccountDetails)
          AccountDetails(handleClose=handleCloseAccountDetails)

        Top(className="top")
          div
            Logo(to="/")
              ReactSVG(src=LogoSVG)

          MobileFlexDiv
            if walletAddress.length
              AddressBalanceC(tablet)
                BalanceBtn(tokens=tokens fromToken=fromToken)
                AddressBtn(handleShowAccountDetails=handleShowAccountDetails walletAddress=walletAddress)

            if showBackBtn
              BackButton(history=history)

            LanguageDropdown

            TransparentBtn( className="svg-btn" onClick=handleShowDrawer)
              ReactSVG(src=MenuBar)

            DrawerComponent(handleCloseDrawer=handleCloseDrawer drawer=drawer)

          DesktopFlexDiv
            FlexCenterDiv
              if showBackBtn
                BackButton(history=history title=t('Back'))
              
              TransparentBtn
                div(className="img-btn")
                  ReactSVG(src=HelpSVG)

                p #{t('help')}
            
            FlexCenterDiv
              TransparentLink(to="/")
                div(className="img-btn")
                  ReactSVG(src=SwapSVG)

                p #{t('swap')}

              if walletAddress.length
                BalanceBtn(tokens=tokens fromToken=fromToken)

              else 
                TransparentLink(to="/" onClick=(e)=>e.preventDefault())
                  div(className="img-btn")
                    ReactSVG(src=StakingSVG)

                  p #{t('staking')}

              if walletAddress.length
                AddressBtn(handleShowAccountDetails=handleShowAccountDetails walletAddress=walletAddress)

              else 
                TransparentLink(to="/"  onClick=(e)=>e.preventDefault())
                  div(className="img-btn")
                    ReactSVG(src=VotingSVG)

                  p #{t('voting')}

            div
              LanguageDropdown

            FlexCenterDiv
              TransparentBtn(onClick=switchDarkMode)
                div(className="img-btn")
                  ReactSVG(src=MoonSVG)

              TransparentBtn(onClick=switchLightMode)
                div(className="img-btn")
                  ReactSVG(src=SunSVG)

        if walletAddress.length
          AddressBalanceC
            BalanceBtn(tokens=tokens fromToken=fromToken)
            AddressBtn(handleShowAccountDetails=handleShowAccountDetails walletAddress=walletAddress)
    `
  )
}

export default React.memo(Header)
