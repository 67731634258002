import { createGlobalStyle } from "styled-components";

export const GlobaStyle = createGlobalStyle`
    *, *::before, *::after {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    :root {
        --main: #e0e0e0;
    }

    h1, h2, h3, h4, h5, h6, p {
        margin: 0;
    }

    a {
        text-decoration: none;
        color: #333;

        &:hover {
            color: ${p => p.theme.colors.green};
        }
    }

    body {
        background: ${p => p.theme.colors.main};
        font-family: sans-serif;

        .walletconnect-modal__base {
            max-width: 400px !important;
        }

        img {
            object-fit: contain;
        }
        
        /* popup over */
        .css-3bmhjh-MuiPaper-root-MuiPopover-paper {
            padding: 0 0.5rem;
        }
    }

    .d-flex {
        display: flex;
    }

    .d-none {
        display: none;
    }

    .d-block {
        display: block;
    }

    .is-active {
        background: rgba(122, 110, 170, 0.4);
        color: rgb(40, 13, 95) !important;
    }
`