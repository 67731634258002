import React from 'react'
import pug from 'pug'
import CloseSVG from "../../assets/times.svg"
import {ReactSVG} from "react-svg"
import { useTranslation } from 'react-i18next'
import { Container ,Header, CloseArea, Heading, Content , SubHeading, Text, SubHeadingTwo,
  TextGroup, GroupHeading, Footer, Button } from "./termsandconditionstyle"
import {closeTermsAndConditionModal} from "../../redux/slices/basicFeaturesSlice"
import { useDispatch } from 'react-redux'
import { setTermsAndCondition } from '../../redux/slices/dappSlice'
import { useHistory } from 'react-router-dom'

export const TermsAndCondition = () => {
  const { t  }   = useTranslation()
  const dispatch = useDispatch();
  const history = useHistory()

  // const getLink = (link) => <a className="link-tag" href={link} target="_blank">{link}</a>

  const agree = () => {
    dispatch(setTermsAndCondition(true));
    dispatch(closeTermsAndConditionModal());
  }
  const disagree = () => {
    dispatch(setTermsAndCondition(false));
    dispatch(closeTermsAndConditionModal());
    history.push("/");
  }
    return (
      pug`
        Container
          CloseArea
            ReactSVG(src=CloseSVG onClick=()=>dispatch(closeTermsAndConditionModal()))

          Header
            Heading #{t('Terms & Conditions')}

          Content
            SubHeading 
              span CVCOIN ERC-20 SWAP TO CVNX GOVERNANCE TOKEN 

              br

              span TERMS AND CONDITIONS
            
            Text 
              span November, 30

              br

              span 2021
            
            Text Your swap of CVCOIN ERC-20 Ethereum based utility token for CVNX ERC20 Governance token (hereinafter referred to as CVNX), on Ethereum blockchain during the Swap Period (as defined in these Terms) is subject to these Terms and Conitions. Both the CVCOIN ERC-20 Tokens and CVNX Governance Tokens were developed by CVChain GmbH, (hereinafter referred to as Company) incorporated in the Switzerland, Zug. 
            
            Text These Terms and Conditions take effect, and become binding on you, when you (1) click an "I Agree" button, check box or other indicator of agreement presented with these Terms or, if earlier, (2) After completion the swap of CVCOIN ERC-20 for CVNX Governance Tokens  during the Swap Period, you will be bound by these Terms and Conditions, as well as incorporated by references.
            
            Text PLEASE READ THESE TOKEN SWAP TERMS AND CONDITIONS (THE “TERMS”) CAREFULLY, AS THEY CONTAIN AN AGREEMENT TO ARBITRATE AND OTHER IMPORTANT INFORMATION REGARDING YOUR LEGAL RIGHTS, REMEDIES AND OBLIGATIONS. THE AGREEMENT TO ARBITRATE (SET FORTH IN SECTION 17 BELOW) REQUIRES (WITH LIMITED EXCEPTIONS) THAT YOU SUBMIT CLAIMS YOU HAVE AGAINST US TO BINDING AND FINAL ARBITRATION, AND FURTHER (1) YOU WILL BE PERMITTED TO PURSUE CLAIMS AGAINST US ONLY ON AN INDIVIDUAL BASIS, NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS OR REPRESENTATIVE ACTION OR PROCEEDING, AND (2) YOU WILL BE PERMITTED TO SEEK RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ONLY ON AN INDIVIDUAL BASIS. IF YOU DO NOT AGREE TO THESE TERMS, DO NOT PARTICIPATE IN THE TOKEN SWAP.
            
            SubHeadingTwo 1. Definitions
            
            TextGroup 
              GroupHeading CVCOIN ERC-20 – 
              Text the original utility token of Crypviser Secure Network issued in 2018 for enabling listing on CEX(“Centralized Exchange”) and providing market liquidity. 

            TextGroup 
              GroupHeading CVNX Governance Token(“CVNX) –
              Text a governance token issued in ERC-20 format to power the next generation of Crypviser 2.0 Smart Platform and introduce new community voting, DeFi, stacking and other uptrend features.

            TextGroup 
              GroupHeading Crypviser Secure Network –
              Text is a decentralized secure network developed by Crypviser GmbH to provide encrypted social and business communication services through blockchain DPKI(“Decentralized Public Key Infrastructure”) and Automated Encryption(“AE”) protocols 

            TextGroup 
              GroupHeading Crypviser 2.0 Smart Platform – 
              Text is the next generation of Crypviser Network, a secure decentralized blockchain designed to provide a wide set of digital services, DeFi protocols, and secure communication features to Enterprise businesses, Smart Companies (DAO), and Private Communities.

            TextGroup 
              GroupHeading ERC20 - 
              Text is a technical standard used for smart contracts on the Ethereum Blockchain for implementing tokens.
              
            TextGroup 
              GroupHeading Ethereum Blockchain - 
              Text Ethereum is an open-source, public, blockchain-based distributed computing platform and operating system. For the purpose of these Terms, Ethereum Blockchain means a version of the Ethereum Blockchain supported by the Ethereum Foundation.

            SubHeadingTwo 2. The Subject

            Text 
              span The token swap is being a part of the CVCOIN Transformation Plan, publicly announced in the beginning of 2020 to simplify Crypviser tokenomic model, migrate to Huobi ECO Chain, and introduce new DeFi and NFT features in the Crypviser 2.0 Smart Platform.

              br

              span The primary purpose of CVNX is to give control back to users. The CVNX governance token will confer holders the power to inﬂuence decisions concerning the Crypviser 2.0 Smart Platform core parameters, products, and features development roadmap, business plan and pricing, and other beneﬁts.

            Text The CVNX token will be listed and available for buy, sell and trade on different decentralized(“DEX”) and centralized(“CEX”) crypto exchanges.

            Text The Company makes no (and disclaims all) warranties or representations and offers no assurances (in each case whether express or implied) that CVNX token will confer any specific market value in the future.

            Text The CVNX token doesn’t confer any rights, whether express or implied, other than limited rights relating to the provision and receipt of Services in the Crypviser 2.0 Smart Platform, subject to limitations and conditions in the Crypviser 2.0 Smart Platform Terms and Policies (as defined below). The CVNX token is not intended to be a digital currency, security, commodity or any other kind of financial instrument and these Terms do not constitute an invitation  to subscribe to any securities.

            SubHeadingTwo 3. Token Swap Procedures

            Text The token swap procedure will be performed according to the below technical requirements:

            Text 3.1. The Company has deployed the CVNX smart contract on the Ethereum blockchain as the only method for exchanging CVCOIN ERC-20 Tokens for CVNX Tokens during the Swap Period. Access to this smart contract is available through the CVNX dApp (https://cvnx.crypviser.com). To the extent that any third party website, service or smart contract offers CVNX Tokens during the Swap Period or facilitates the sale, exchange or transfer of CVNX Tokens in any way during the Swap Period or at any time up to the release of the CVNX Tokens, such third party websites or services are, unless explicitly mentioned on the CVN dApp website, not authorized by the Company and have no relationship in any way with the Company or Crypviser, and such CVNX Tokens shall be invalid.

            Text 3.2. CVCOIN ERC-20 Tokens are accepted in exchange for CVNX Tokens. The CVNX  swap contract is deployed on the 0xA1a4E303e9C56962F201C5e834abC1E677A3C4F3 address of the Ethereum blockchain and does not accept any other type of token.

            Text 3.3. You are holding CVCOIN ERC-20 utility tokens on the Ethereum Mainnet with the smart contract address:  0x62aaf435273bc4baa78dcebd6590042d7e58ba6f

            Text 3.4. You are holding CVCOIN ERC-20 tokens in Ethereum compatible ERC20 wallet (such as MetaMask, TrustWallet, MEW, ConnectWallet), which allows the receipt, storage and transfer of CVNX ERC-20 Tokens.

            Text 3.5. The minimum amount of CVCOIN ERC-20 that you can swap is 1 token. The maximum amount is not limited. 

            Text 3.6. CVCOIN-ERC20 Tokens will be swapped on a one to one (1:1) basis to CVNX.

            Text 3.7. The token swap is fully automated by CVNX smart contract. To process with the swap, you must follow the given instructions in CVNX dApp (https://cvnx.crypviser.com)

            Text 3.8. You agree to bear the "gas fee" cost of the swap transaction in the Ethereum blockchain at your expenses. The cost of Ethereum "gas fee" is defined by miners and out of the Company control.

            Text 3.9. After successfully proceeding the swap, you will receive the same amount of CVNX to the connected wallet address.  The transaction speed solely depends on the Ethereum blockchain performance and might take up to 1(one) hour. 

            Text 3.10. 
              span In order to see the balance of CVNX token in your wallet, you should add a custom token by using the following smart contract details:

              br

              span Token name: CVNX 

              br

              span Token contract: 0xA1a4E303e9C56962F201C5e834abC1E677A3C4F3

              br

              span Decimals: 18 

              br

              span NOTE: In many wallets, such as MetaMask, TrustWallet, you can specify the token address only. The rest fields will be auto filled automatically. 

            Text In case of any misunderstandings, technical issues or any doubts, please contact us the CVCOIN support team by cvcoin@crypviser.net or in Telegram live chat at https://t.me/crypviser_group

            SubHeadingTwo 4. Scope of Terms

            Text Unless otherwise stated in these Terms, these Terms govern only you the swap of CVCOIN ERC-20 Tokens for CVNX Tokens during the Swap Period (the “Token Swap”).

            Text YOU AGREE AND CERTIFY THAT YOU ARE SWAPPINIG CVCOIN ERC-20 TOKENS FOR CVNX TOKENS DURING THE TOKEN SWAP FOR YOUR OWN PERSONAL USE AND UTILITY AND TO PARTICIPATE ON THE CRYPVISER 2.0 SMART PLATFORM, AND NOT FOR EARNING PROFIT OR FINANCIAL PURPOSES. YOU AGREE AND CERTIFY THAT TOKENS ARE NOT A SECURITY OR A DIGITAL CURRENCY AND YOU ACKNOWLEDGE THAT THE TOKENS MAY LOSE IT’S MARKET VALUE. 

            Text CVNX token is a cryptographic token issued on the Ethereum blockchain. If you did not understand any of the concepts identified in these Terms, please contact us at cvcoin@crypviser.net By agreeing to these Terms you affirm that you understand and agree with all concepts described in the specified documents to your satisfaction.

            SubHeadingTwo 5. Eligibility

            Text 5.1. You understand and accept that while the individuals and entities, including Crypviser GmbH, assigned to this task, will make reasonable efforts to continue to develop and complete the Crypviser 2.0 Smart Platform. It is possible that such development may fail and your Tokens may become useless and/or valueless due to technical, commercial, regulatory or any other reasons.

            Text 5.2. You are aware of the risk that even if all or parts of the Crypviser 2.0 Smart Platform are successfully developed and released in full or in part, due to a lack of public interest, the Crypviser 2.0 Smart Platform could be fully or partially abandoned, remain commercially unsuccessful or shut down for lack of interest, regulatory or other reasons. You therefore understand and accept that your swap of CVCOIN ERC-20 Tokens for CVNX Tokens carries significant financial, regulatory and/or reputational risks.

            Text 5.3. Crypviser 2.0 Smart Platform. By swapping CVCOIN ERC-20 Tokens for CVNX Tokens, no form of partnership, joint venture, agency or any similar relationship between you and the Company and/or other individuals or entities involved with the deployment or operation of the Crypviser 2.0 Smart Platform is created.

            SubHeadingTwo 6. Cancellation; Rescission.

            Text Your swap of CVCOIN ERC-20 Tokens for CVNX Tokens during the Swap Period is final, and there are no refunds, cancellations or rescissions except as may be required by applicable law or regulation. We will provide you web-based support only, in our sole discretion, which support may include email, web forums, and knowledge base support. We will not provide telephone or live support. Our support email can be found on the CVNX dApp web site. By participating in the Token Swap, you acknowledge and agree unconditionally and irrevocably to waive any right of set-off, netting, counterclaim, abatement or other similar remedy which you might otherwise have in respect of any Tokens or under the laws of any jurisdiction.

            SubHeadingTwo 7. Token Swap Period

            Text CVCOIN ERC-20 Tokens can be swapped to CVNX beginning on November 30, 2021, (such period is hereafter called the “Swap Period”).

            SubHeadingTwo 8. Acknowledgement and Assumption of Risks.

            Text You acknowledge and agree that there are risks associated with purchasing Tokens, holding Tokens, swapping Tokens, and using Tokens for providing or receiving Services in the Crypviser 2.0 Smart Platform, including, without limitation, those disclosed and explained below (the “Risk Disclosures”). You assume all of these risks. If you have any questions regarding these risks, please contact us at cvcoin@crypviser.net 

            Text BY PARTICIPATING IN THE TOKEN SWAP, YOU EXPRESSLY ACKNOWLEDGE AND ASSUME ALL OF THESE RISKS.

            Text 8.1. Risk of Losing Access to Tokens Due to Wallet Incompatibility: Your cryptocurrency wallet must possess technical infrastructure that is compatible with the receipt, storage and transfer of the Ethereum blockchain, meaning it must be ERC-20 compatible. Non-ERC-20-compatible wallet addresses will not be accepted. In addition, your wallet address must not be associated with a third party exchange or service that has custody over the private key. The Company reserves the right to prescribe additional conditions relating to specific wallet requirements for the Token Swap at any time, acting in its sole discretion.

            Text 8.2. Risks Associated with the Blockchain Protocols: CVNX Tokens are based on the Ethereum smart contract. As such, any malfunction, breakdown, abandonment, unintended function, unexpected functioning of or attack on the Ethereum blockchain may have an adverse effect on the CVNX Tokens, respectively, including causing them to malfunction or function in an unexpected or unintended manner.

            Text 8.3. Risks Associated with Your Credentials: Any third party that gains access to or learns of your wallet login credentials or private keys may be able to dispose of your Tokens. To minimize this risk, you should guard against unauthorized access to your electronic devices. Best practices dictate that you safely store private keys in one or more backup locations geographically separated from the working location. In addition, you are responsible for giving us the correct wallet address to which to send your Tokens. If you give us the incorrect address to which to send your Tokens, we are not responsible for any loss of Tokens that may occur.

            Text 8.4. 
              span Risk of Unfavorable Regulatory Action in One or More Jurisdictions:
              span Blockchain technologies and cryptographic tokens have been the subject of scrutiny by various regulatory bodies around the world. Blockchain technology allows new forms of interaction and it is possible that certain jurisdictions will apply existing regulations on, or introduce new regulations addressing, blockchain technology based applications, which regulations may be contrary to the current setup of the Crypviser 2.0 Smart Platform or its smart contract system and, therefore, may result in substantial modifications to the E Crypviser 2.0 Smart Platform and such smart contract systems, including its termination and the loss of Tokens.

            Text 8.5. The regulatory status of cryptographic tokens and distributed ledger technology is unclear or unsettled in many jurisdictions. It is difficult to predict how or whether regulatory authorities may apply existing regulation with respect to such technology and its applications, including specifically (but without limitation to) the Crypviser 2.0 Smart Platform and Tokens. It is likewise difficult to predict how or whether any legislative or regulatory authorities may implement changes to law and regulation affecting distributed ledger technology and its applications, including specifically (but without limitation to) the Crypviser 2.0 Smart Platform and Tokens. Regulatory actions could negatively impact the Crypviser 2.0 Smart Platform and Tokens in various ways, including, for purposes of illustration only, through a determination that Tokens are a regulated financial instrument that requires registration, licensing or restriction. The Company may cease operations in a jurisdiction if regulatory actions, or changes to law or regulation, make it illegal to operate in such jurisdiction, or commercially undesirable to obtain the necessary regulatory approval(s) to operate in such jurisdiction. The functioning of the Crypviser 2.0 Smart Platform and the Tokens could be impacted by any regulatory inquiries or actions, including restrictions on the use, sale or possession of digital tokens like the Tokens, which restrictions could impede, limit or end the development of the Crypviser 2.0 Smart Platform and increase legal costs.

            Text 8.6. The cryptocurrency exchange market, the token listing and trading market are subject to a variety of federal, state and international laws and regulations, including those with respect to “know you customer” and “anti-money laundering” and customer due diligence procedures, privacy and data protection, consumer protection, data security, and others. These laws and regulations, and the interpretation or application of these laws and regulations, could change. In addition, new laws or regulations affecting the Crypviser 2.0 Smart Platform could be enacted, which could impact the utility of Tokens in the Crypviser 2.0 Smart Platform. Additionally, Crypviser 2.0 Smart Platform users are subject to or may be adversely affected by industry specific laws and regulations or licensing requirements. If any of these parties fails to comply with any of these licensing requirements or other applicable laws or regulations, or if such laws and regulations or licensing requirements become more stringent or are otherwise expanded, it could adversely impact the Crypviser 2.0 Smart Platform and the Tokens, including the utility of Tokens to obtain or provide Services within the Crypviser 2.0 Smart Platform.

            Text 8.7. Risk of Insufficient Interest in Crypviser 2.0 Smart Platform or Distributed Applications: It is possible that the Crypviser 2.0 Smart Platform will not be used by a large number of businesses, individuals, and other organizations and that there will be limited public interest in the creation and development of distributed applications. Such a lack of interest could negatively impact the CVNX Tokens and the Crypviser 2.0 Smart Platform

            Text 8.8. Risk that the Crypviser 2.0 Smart Platform, As Developed, Will Not Meet Expectations: Crypviser 2.0 Smart Platform presently is under continued development and may undergo significant changes. Any expectations or assumptions regarding the form and functionality of the Crypviser 2.0 Smart Platform or the Tokens (including participant behavior) held by the Company or you may not be met, for any number of reasons, including, without limitation, mistaken assumptions or analysis, a change in the design and implementation plans, and changes in the execution of the Crypviser 2.0 Smart Platform. Moreover, we may not be able to retain full and effective control over how other participants will use the Crypviser 2.0 Smart Platform, what products or services will be offered through the Crypviser 2.0 Smart Platform to third parties, or how third-party products and services will utilize Tokens (if at all). This could create the risk that the Tokens or Crypviser 2.0 Smart Platform, as further developed and maintained, may not meet your expectations. Furthermore, despite our good faith efforts to develop and participate in the Crypviser 2.0 Smart Platform, it is still possible that the Crypviser 2.0 Smart Platform will experience malfunctions or otherwise fail to be adequately maintained, which may negatively impact the Crypviser 2.0 Smart Platform and Tokens, and the potential utility of the Tokens, including the utility of the Tokens for obtaining services and/or offering rewards and/or being used in the manner intended at the time of the Token Swap.

            Text 8.9. Inability to Fund Development or Maintenance: The Company may not be able to fund development, or may not be able to develop or maintain the Crypviser 2.0 Smart Platform in the manner that it intended.

            Text 8.10. Risks from Taxation: The tax characterization of the Tokens is uncertain. You must seek your own tax advice in connection with acquiring and holding Tokens, which may result in adverse tax consequences to you, including withholding taxes, income taxes, and tax reporting requirements.

            Text 8.11. Risk of Lack of Adoption or Use of the Crypviser 2.0 Smart Platform: While the Tokens should not be viewed as an investment, they may have value over time. That value may be limited or non-existent if the Crypviser 2.0 Smart Platform lacks acceptance, use and adoption, which may have an adverse impact on the Tokens.

            Text 8.12. Risk of an volatile Markets and Token value: The Company will be opening new CVNX markets and thus the markets may be extremely volatile. If (despite your representations to us to the contrary) you are holding Tokens as a form of investment on a speculative basis or otherwise, or for a financial purpose, with the expectation or desire that their inherent, intrinsic or cash-equivalent value may increase with time, you assume all risks associated with such speculation or actions, and any errors associated therewith, and accept that the Tokens are not offered by the Company or its affiliates on an investment basis.

            Text 8.13. Risk of Uninsured Losses: Unlike bank accounts or accounts at some other financial institutions, funds held using the Ethereum blockchain are generally uninsured. In the event of any loss, there is no public insurer, such as the FDIC, or private insurer, to offer recourse to you.

            Text 8.14. Risk of Dissolution of Crypviser 2.0 Smart Platform: It is possible that, due to any number of reasons, including development issues with the Crypviser 2.0 Smart Platform, the failure of business relationships, lack of public interest, lack of funding, the Crypviser 2.0 Smart Platform may no longer be viable as a business or otherwise and may dissolve or fail to maintain commercial or legal viability, or be abandoned. There is no assurance that you will receive any benefits through the Tokens.

            SubHeadingTwo 9. Security and Privacy

            Text You are responsible for implementing reasonable measures for securing the wallet, vault or other storage mechanism you use to receive and hold CVNX Tokens, including any requisite private key(s) or other credentials necessary to access such storage mechanism(s). If your private key(s) or other access credentials are lost, you may lose access to your CVNX Tokens. We are not responsible for any such losses. You will implement reasonable and appropriate measures designed to secure access to (i) any device connected with the email address associated with your account, (ii) private keys required to access your CVNX Tokens, and (iii) your username, password and any other login or identifying credentials. If you are no longer in possession of any device connected with your account or are not able to provide your login or identifying credentials, we may, in our sole discretion and only if we are able, grant access to your account to any person providing additional credentials to us. We reserve the right to determine the additional credentials required, which may include a sworn, notarized statement of identity.

            SubHeadingTwo 10. Taxes.

            Text You are responsible for determining what, if any, taxes apply to your swap of CVCOIN ERC-20 Tokens for CVNX Tokens, including, for example, sales, use, value added, and similar taxes. It is also your responsibility to withhold, collect, report and remit the correct taxes to the appropriate tax authorities. We are not responsible for withholding, collecting, reporting, or remitting any sales, use, value added, or other tax arising from your swap of CVCOIN ERC-20 Tokens for CVNX Tokens. You agree not to hold the Company or any of the Company Parties liable for any tax liability associated with or arising from the creation, ownership, use or liquidation of Tokens or any other action or transaction related to the Crypviser 2.0 Smart Platform or the Token Swap.

            SubHeadingTwo 11. Representations and Warranties.

            Text By swapping your CVCOIN ERC-20 Tokens to CVNX Tokens, you represent, warrant and covenant that:

            Text 11.1 It is your responsibility to participate in the Crypviser 2.0 Smart Platform in some manner, meaning that the ecosystem surrounding the CVNX is NOT solely dependent upon the efforts of the Company, but by the Token holders engaging with the CVNX and Crypviser 2.0 Smart Platform.

            Text 11.2. You are not a citizen or resident of a country whose legislation conflicts with the Tokens Swap and/or the Crypviser 2.0 Smart Platform in general.

            Text 11.3. You have sufficient understanding of cryptographic tokens, token storage mechanisms (such as token wallets), and blockchain technology to understand these Terms and to appreciate the risks and implications of swapping your CVCOIN ERC-20 Tokens for CVNX Tokens.

            Text 11.4. You have read and understand these Terms, and are entering into these Terms with us voluntarily and based on your own independent judgment and on advice from independent advisors as you have considered to be necessary or appropriate, after due inquiry.

            Text 11.5. You have obtained sufficient information about the EQ Tokens to make an informed decision to swap your CVCOIN ERC-20 Tokens for CVNX Tokens.

            Text 11.6. 
              span You are legally permitted to receive the CVNX Tokens.
              span If you are exchanging CVCOIN ERC-20 Tokens for CVNX Tokens on behalf of any entity, (i) you are authorized to accept these Terms on such entity's behalf and such entity will be responsible for breach of these Terms by you or any other employee or agent of such entity (references to "you" in these Terms refer to you and such entity, jointly); (ii) the acceptance of these Terms and the entry into a binding agreement with the Company will not result in any breach of, be in conflict with, or constitute a material default under: (A) any provision of such entity’s constitutional or organizational documents (in the case of a corporate entity including, without limitation, any company or partnership); (B) any provision of any judgment, decree or order imposed on such entity by any court or governmental or regulatory authority; and/or (C) any material agreement, obligation, duty or commitment to which such entity is a party or by which such entity is bound; and (iii) such entity is duly incorporated, registered and validly existing under the applicable laws of the jurisdiction in which the entity is established. m. If you are a corporation, company, partnership or other “non-natural person” entity, (i) the acceptance of these Terms and the entry into a binding agreement with the Company will not result in any breach of, be in conflict with, or constitute a material default under: (A) any provision of your constitutional or organizational documents (in the case of a corporate entity including, without limitation, any company or partnership); (B) any provision of any judgment, decree or order imposed on you by any court or governmental or regulatory authority; and/or (C) any material agreement, obligation, duty or commitment to which you are a party or by which you are bound; and (ii) you are duly incorporated or organized, registered and validly existing under the applicable laws of the jurisdiction in which you are established.

            Text 11.7. You are not a governmental or semi-governmental authority.

            Text 11.8. You are not Swapping Tokens from countries or regions comprehensively sanctioned by the U.S. Office of Foreign Assets Control ("OFAC") 

            Text 11.9. Tokens are not intended to be a digital currency, security, commodity or any other kind of financial instrument.

            Text 11.10. These Terms shall not be construed as an invitation to subscribe for any securities, and you understand and acknowledge that no actions of, or documentation issued by the Company, shall be construed as such.

            Text 11.11 You are of a sufficient age to legally obtain and use CVNX Tokens.

            Text 11.12. With regard to the Tokens, we make no guarantees that you will be able to resell the Tokens, or as to their future value, and that no market liquidity may be guaranteed and that the value of the Tokens over time may experience extreme volatility or depreciate in full.

            Text 11.13. You waive the right to participate in a class action lawsuit or a class wide arbitration against any entity or individual involved with the creation of the Tokens or the Token Swap.

            Text 11.14. The creation of the Tokens and the Token Swap do not involve the purchase of shares, securities or any equivalent in any existing or future public or private company, corporation or other entity in any jurisdiction. You have no right against any other party to request any refund of CVCOIN-ERC20 Tokens exchanged for CVNX Tokens, under any circumstances.

            Text 11.15. You will not use Tokens to finance, engage in or otherwise support any unlawful activities.

            SubHeadingTwo 12. Your Responsibilities.

            Text You are responsible for properly configuring any software in connection with your access to, or use of, CVNX Tokens. 

            SubHeadingTwo 13. Proprietary Rights.

            Text Intellectual Property. We retain all right, title and interest in all of our intellectual property, including without limitation (i) all patents, designs, copyright and related rights, database rights, knowhow and confidential information, trademarks and related goodwill, trade names (whether registered or unregistered), and rights to apply for registration, inventions, discoveries, processes, methods, compositions, formulae, techniques, information and data, whether or not patentable, copyrightable or protectable in trademark, and all trademarks, copyrights or patents based thereon; (ii) all other rights of a similar nature or having an equivalent effect anywhere in the world which currently exist or are recognized in the future; and (iii) all applications, extensions and renewals in relation to any such rights (collectively, the “Company IP Rights”). You may not use any of the Company IP Rights for any reason, except with our express, prior, written consent which may be granted by us in our sole discretion and, if granted, thereafter revoked by us at any time. Except as expressly set out in these Terms, you are not entitled, for any purpose, to any of the Company’s IP Rights. We at all times retain ownership, including all rights, title and interests in and to the Company’s IP Rights and you understand and accept that by accepting the Token Swap pursuant to these Terms you will not (A) acquire or otherwise be entitled to any Company’s IP Rights; (B) make a claim in respect of any Company’s IP Rights or any other equivalent rights; or (C) use, attempt to use, copy, imitate or modify (whether in whole or in part) any Company’s IP Rights, except with our prior written consent.

            SubHeadingTwo 14. Indemnification.

            Text 14.1. To the fullest extent permitted by applicable law, you will indemnify, defend and hold harmless the Company and its parent companies, subsidiaries and affiliates, and its and their respective past, present and future employees, officers, directors, managers, contractors, consultants, equity holders, suppliers, vendors, service providers, agents, representatives, predecessors, successors and assigns (the "Company Parties"), on demand from and against all claims, demands, actions, damages, losses, costs and expenses (including attorneys' fees, court costs, investigative costs, amounts paid in settlement and other costs and expenses) that arise from or relate to: (i) your swap of CVCOIN ERC20 Tokens to CVNX Tokens, (ii) the performance or non-performance of your responsibilities and obligations under these Terms, (iii) your violation of these Terms, or (iv) your violation of any rights of any other person or entity.

            Text 14.2. The Company reserves the right to exercise sole control over the defense, at your cost and expense, of any claim subject to indemnification under this Section 14.

            SubHeadingTwo 15. Disclaimers.

            Text 15.1. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW AND EXCEPT AS OTHERWISE SPECIFIED IN A WRITING BY US, (i) THE CVNX TOKENS ARE ISSUED IN THE TOKEN SWAP ON AN "AS IS" AND "AS AVAILABLE" BASIS WITHOUT WARRANTIES OF ANY KIND, AND WE EXPRESSLY DISCLAIM ALL IMPLIED WARRANTIES AS TO THE TOKENS, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT; (ii) WE CANNOT AND DO NOT REPRESENT OR WARRANT THAT THE TOKENS ARE RELIABLE, CURRENT OR ERROR-FREE, MEET YOUR REQUIREMENTS, OR THAT DEFECTS IN THE CVNX TOKENS WILL BE CORRECTED; AND (iii) WE CANNOT AND DO NOT WARRANT ANY CURRENT AND FUTURE MARKET VALUE OF CVNX TOKEN

            Text d. TRANSACTIONS USING BLOCKCHAIN TECHNOLOGY, SUCH AS THOSE INVOLVING THE TOKEN SWAP, ARE AT RISK TO MULTIPLE POTENTIAL FAILURES, INCLUDING, COMPUTER FAILURE, BLOCKCHAIN FAILURE OF ANY KIND, USER FAILURE, INTERNET FAILURE OR DISRUPTION. WE ARE NOT RESPONSIBLE FOR ANY LOSS OF DATA, TOKENS OR OTHER CRYPTOCURRENCY, HARDWARE OR SOFTWARE RESULTING FROM ANY TYPES OF FAILURES.

            SubHeadingTwo 16. Limitation of Liability.

            Text 16.1. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW,  IN NO EVENT WILL THE COMPANY OR ANY OF THE COMPANY PARTIES BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES OF ANY KIND (INCLUDING, BUT NOT LIMITED TO, LOSS OF REVENUE, INCOME OR PROFITS; UNREALIZED GAIN OR SAVINGS; LOSS OF USE OR DATA; OR DAMAGES FOR BUSINESS INTERRUPTION, OR LOSS OF TOKENS) ARISING OUT OF OR IN ANY WAY RELATED TO THESE TERMS, THE TOKENS, THE TOKEN SWAP OR THE USE OF, OR INABILITY TO USE, THE TOKENS, REGARDLESS OF THE FORM OF ACTION, WHETHER BASED IN CONTRACT, TORT (INCLUDING, BUT NOT LIMITED TO, SIMPLE NEGLIGENCE, WHETHER ACTIVE, PASSIVE OR IMPUTED), OR INFRINGEMENT OR ANY OTHER LEGAL OR EQUITABLE THEORY (EVEN IF THE PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES AND REGARDLESS OF WHETHER SUCH DAMAGES WERE FORESEEABLE); 

            Text 16.2. IN NO EVENT WILL THE AGGREGATE LIABILITY OF COMPANY AND THE COMPANY PARTIES (JOINTLY), WHETHER IN CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE, WHETHER ACTIVE, PASSIVE OR IMPUTED), OR INFRINGEMENT OR OTHER THEORY, ARISING OUT OF OR IN ANY WAY RELATED TO THESE TERMS, THE TOKENS, THE TOKEN SWAP OR THE USE OF, OR INABILITY TO USE, THE TOKENS, EXCEED US$1000.

            Text 16.3. YOU AGREE THAT, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ANY CLAIM OR CAUSE OF ACTION ARISING OUT OF OR RELATING TO THE TOKEN SWAP OR THESE TERMS MUST BE FILED WITHIN 6 MONTHS (HALL YEAR) AFTER THE END OF THE SWAP PERIOD OR IT WILL BE FOREVER BARRED.

            SubHeadingTwo 17. Dispute Resolution; Arbitration.

            Text 17.1. Binding Arbitration. Except for any controversies, disputes, demands, claims, causes of actions, or suits, actions or proceedings (collectively, "Disputes") in which either Party seeks injunctive or other equitable relief for the alleged unlawful use of intellectual property, including, without limitation, copyrights, trademarks, trade names, logos, trade secrets or patents, or any other disputes or claims that for any reason do not fall within the agreement to arbitrate set forth in this Section 17, you (i) waive your rights to have any and all Disputes arising out of or in any way related to these Terms, the Tokens, the Token Swap or the use of, or inability to use, the Tokens resolved in a court, and (ii) waive your rights to a jury trial. Instead, you shall arbitrate all Disputes between you and any Company Party (including, without limitation, all Disputes concerning the interpretation and scope of this Section 17, the arbitrability of the controversy, dispute, demand, claim, or cause of action, or any related or prior agreement that you may have or have had with us) through binding and confidential arbitration (which is the referral of a Dispute to one or more persons charged with reviewing the Dispute and making a final and binding determination to resolve it instead of having the Dispute decided by a judge or jury in court).

            Text 17.2. No Class Arbitrations, Class Actions or Representative Actions. Any Dispute arising out of or related to these Terms is personal to you and the Company and will be resolved solely through individual arbitration and will not be brought as a class arbitration, class action or any other type of representative proceeding. There will be no class arbitration or arbitration in which an individual attempts to resolve a Dispute as a representative of another individual or group of individuals. Further, a Dispute cannot be brought as a class or other type of representative action, whether within or outside of arbitration, or on behalf of any other individual or group of individuals.

            Text 17.3. To seek arbitration, you must first send to us, by certified mail, a written Notice of Dispute ("Notice") by email to legal@crypviser.net. The Notice must (i) describe the nature and basis of the Dispute and (ii) set forth the specific relief sought. If we and you do not resolve the Dispute within sixty (60) calendar days after the Notice is received, you or we may commence an arbitration proceeding. During the arbitration, the amount of any settlement offer made by us or you shall not be disclosed to the arbitrator until after the arbitrator determines the amount, if any, to which you or we are entitled. As used in this Section 17, "we" and "us" includes, without limitation, any third party providing any product, service, or benefit in connection with the Tokens or these Terms if such third party is named as a co-party with us in any Dispute subject to this Section 17.

            Text 17.4. The seat of the arbitration is Switzerland. The venue of the arbitration will be the Switzerland. The language of the arbitration will be German. The arbitration will be conducted before one commercial arbitrator from the International Chamber of Commerce ("ICC") with substantial experience in resolving commercial contract disputes. As modified by these Terms, and unless otherwise agreed upon by the Parties in writing, the arbitration will be governed by the International Chamber of Commerce Rules of Arbitration (collectively, the "Rules and Procedures"). By agreeing to be bound by these Terms, you either (i) acknowledge and agree that you have read and understand the Rules and Procedures, or (ii) waive your opportunity to read the Rules and Procedures and any claim that the Rules and Procedures are unfair or should not apply for any reason. Where no claims or counterclaims involved in the Dispute exceed US$10,000, the Dispute will be resolved by the submission of documents without a hearing, unless a hearing is requested by a Party or deemed to be necessary by the arbitrator, in which case, a Party may elect to participate telephonically.

            Text 17.5. You should review this Section 17 carefully. To the extent permitted by applicable law, you are GIVING UP YOUR RIGHT TO GO TO COURT to assert or defend your rights EXCEPT for matters that you file in small claims court in the state or municipality of your residence within the jurisdictional limits of the small claims court and as long as such matter is pending only in that court. Additionally, notwithstanding this agreement to arbitrate, claims of defamation, and infringement or misappropriation of the other Party's patent, copyright, trademark, trade secret or other intellectual property rights shall not be subject to this arbitration agreement. Such claims shall be exclusively brought in a court located in the Cayman Islands. Additionally, notwithstanding this agreement to arbitrate, you or us may seek emergency equitable relief before a court located in the Switzerland in order to maintain the status quo pending arbitration and you shall submit to the exclusive personal jurisdiction of the courts located within the Switzerland for such purpose. A request for interim measures shall not be deemed to be a waiver of the right to arbitrate.

            Text 17.6. your rights will be determined by a NEUTRAL ARBITRATOR and NOT a judge or jury. You are entitled to a FAIR HEARING, BUT the arbitration procedures may be SIMPLER AND MORE LIMITED THAN RULES APPLICABLE IN COURT. Arbitrators' decisions are as enforceable as any court order and are subject to VERY LIMITED REVIEW BY A COURT.

            Text 17.7. You and we must abide by the following rules: (i) ANY CLAIMS BROUGHT BY YOU OR US MUST BE BROUGHT IN THE PARTY'S INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING; (ii) THE ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE PERSON'S CLAIMS, MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A REPRESENTATIVE OR CLASS PROCEEDING, AND MAY NOT AWARD CLASS-WIDE RELIEF; (iii) the arbitrator will honor claims of privilege and privacy recognized at law; (iv) the arbitration will be confidential, and neither you nor we may disclose the existence, content, or results of any arbitration, except as may be required by applicable law or for purposes of enforcement of the arbitration award; (v) subject to the limitation of liability provisions of these Terms, the arbitrator may award any individual relief or individual remedies that are expressly permitted by applicable law; and (vi) you and we will pay our respective attorneys' fees and expenses, unless there is a statutory provision that requires the prevailing party to be paid its fees and litigation expenses and the arbitrator awards such attorneys' fees and expenses to the prevailing party, and, in such instance, the fees and costs awarded will be determined by the applicable law. Payment of all filing, administration, and arbitrator fees (collectively, the "Arbitration Fees") will be governed by the Rules and Procedures, unless otherwise provided in this Arbitration Agreement. Any payment of attorneys' fees will be governed by the Rules and Procedures.

            Text 17.8. All aspects of the arbitration proceeding, and any ruling, decision or award by the arbitrator, will be strictly confidential for the benefit of you and us.

            Text 17.9. Notwithstanding any provision in these Terms to the contrary, we agree that if we make any future change to this Section 17 (other than a change to the Notice Address) while you are a user of the CVNX Tokens, you may reject any such change by sending us written notice within thirty (30) calendar days after the date of the change to the Notice Address provided above. By rejecting any future change, you are agreeing that you will arbitrate any dispute with us in accordance with the language of this Section 17 as of the later of (i) the date you first accepted these Terms and (ii) the date you last accepted any subsequent changes to these Terms.

            Text 17.10. For more information on the ICC, the Rules and Procedures, or the process for filing an arbitration claim, you may visit the ICC website at https://iccwbo.org/disputeresolutionservices/arbitration/rules-of-arbitration.

            SubHeadingTwo 18. Governing Law and Venue.

            Text These Terms will be governed by and construed and enforced in accordance with the laws of the Switzerland, without regard to conflict of law rules or principles (whether of the Switzerland or any other jurisdiction) that would cause the application of the laws of any other jurisdiction. Any Dispute between the Parties arising out of or relating to these Terms (including non-contractual Disputes of claims) will be resolved in accordance with Section 17.

            SubHeadingTwo 19. Severability. 

            Text If a court of competent jurisdiction holds any provision of these Terms to be invalid or unenforceable, the remaining provisions of these Terms will remain in full force and effect. The Parties intend that any invalid or unenforceable provisions will be interpreted to affect the intent of the original provisions. If such construction is not possible, the invalid or unenforceable provision will be severed from these Terms, but the rest of these Terms will remain in full force and effect.

            SubHeadingTwo 20. Modifications to the Terms. 

            Text We may modify these Terms at any time by posting a revised version on the CVN dApp Website, other channel we make available or, only if you have provided us with an email address, by email. The modified provisions will become effective upon posting or the date indicated in the posting, or if we notify you by email, as stated in the email. It is your responsibility to check the CVNX dApp website, Crypviser Blog and other channels regularly for modifications. Your continued use of the CVNX Token after any modification become effective constitutes your acceptance of the modification. We last modified these Terms on the date listed at the beginning of these Terms.

            SubHeadingTwo 21. Miscellaneous.

            Text 21.1. Confidentiality and Publicity. You may use Company Confidential Information (as defined below) only in connection with your swap of CVCOIN ERC-20 to CVNX Tokens and pursuant to these Terms. You will not disclose Company Confidential Information during or after the Swap Period. You will use best efforts to avoid disclosure, dissemination or unauthorized use of Company Confidential Information, including, at a minimum, taking those measures you take to protect your own confidential information of a similar nature. You will not issue any press release or make any other public communication with respect to these Terms or your swap of CVCOIN ERC-20 to CVNX Tokens. You will not misrepresent or embellish the relationship between us and you (including by expressing or implying that we support, sponsor, endorse, or contribute to you or your business endeavors), or express or imply any relationship or affiliation between us and you or any other person. i. "Company Confidential Information" means all nonpublic information disclosed by any Company Party, their respective business partners and their respective employees, contractors or agents that is designated as confidential or that, given the nature of the information or circumstances surrounding its disclosure, reasonably should be understood to be confidential. Company Confidential Information includes, without limitation: (A) nonpublic information relating to any Company Party or their respective business partners' technology, customers, business plans, promotional and marketing activities, finances and other business affairs; (B) third-party information that we are obligated to keep confidential; and (C) the nature, content and existence of any discussions or negotiations between you and any Company Party.

            Text 21.2. Company Confidential Information does not include any information that: (A) is or becomes publicly available without breach of these Terms; (B) you can show conclusively by documentation to have been known to you at the time of your receipt from any Company Party; (C) you can show conclusively by documentation to have been received by you from a third party who did not acquire, use or disclose the same by a wrongful or tortious act; or (D) you can show conclusively by documentation to have been independently developed by you without reference to any Company Confidential Information.

            Text 21.3. Force Majeure. None of the Company Parties will be liable for any delay or failure to perform any obligation under these Terms where the delay or failure results from any force, event or condition outside of its control, including acts of God, labor disputes or other industrial disturbances, electrical, telecommunications, hardware, software or other utility failures, earthquake, storms or other elements of nature, blockages, embargoes, riots, acts or orders of government, acts of terrorism, war, changes in blockchain technology and changes in the Crypviser 2.0 Smart Platform protocols.

            Text 21.4. Independent Contractors. We and you are independent contractors, and neither Party, nor any of their respective affiliates, is an agent of the other for any purpose or has the authority to bind the other.

            Text 21.5. No Third Party Beneficiaries. These Terms do not create any third party beneficiary rights in any person except for any of our affiliates or licensors or other Company Parties as provided in these Terms.

            Text 21.6. Notices. All communications and notices to be made or given pursuant to these Terms must be in the English language.

            Text 21.7. We may provide any notice to you under these Terms by: (A) posting a notice on the CVNX dApp Website, Crypviser Blog and public channels; or (B) sending an email to the email address then associated with your account. Notices we provide by posting on the CVNX dApp Website, Crypviser Blog and public channels will be effective upon posting and notices we provide by email will be effective when we send the email. It is your responsibility to keep your email address current and active. You will be deemed to have received any email sent to the email address then associated with your account when we send the email, whether or not you actually receive or read the email.

            Text 21.8. To give us notice under these Terms, you must contact the Company by email to cvcoin@crypviser.net 

            Text 21.9. Assignment. You will not assign these Terms, or delegate or sublicense any of your rights under these Terms, without our prior written consent. Any assignment or transfer in violation of this Section 21(h) will be void. We may assign these Terms or any of its provisions without your consent. Subject to the foregoing, these Terms will be binding upon, and inure to the benefit of the parties and their respective successors and permitted assigns.

            Text 21.10. No Waivers. The failure by us to enforce any provision of these Terms will not constitute a present or future waiver of such provision nor limit our right to enforce such provision at a later time. All waivers by us must be in writing to be effective.

            Text 21.11. Entire Agreement. These Terms (including the web links, other agreements and instruments referred to in these Terms) constitute the entire agreement among the parties and supersede all prior agreements and understandings, both written and oral, among the parties with respect to the subject matter hereof and thereof.

          Footer
            Button(onClick=agree) I Read and  Agree
            Button(onClick=disagree) Disagree
      `
    )
}