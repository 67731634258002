import React, { memo, useCallback, useEffect, useState } from 'react'
import pug from "pug";
import { } from "../../accountDetailsStyle"
import { Container, ConnectionTitle,Msg, AllTransactions,  ViewMore, BtnContainer } from "./transactionsStyle"
import { useDispatch, useSelector } from 'react-redux';
import { getNoOfDataFromArr } from "../../../../common/common"
import FullScreenLoader from "../../../fullScreenLoader";
import axios from "axios"
import SingleTransaction from '../singleTransaction';
import { setTransactions } from '../../../../redux/slices/dappSlice';

export const Transactions = memo(() => {
  const {walletAddress, transactions} = useSelector(({ dapp }) => dapp);

  const [visibleTransactions, setVisibleTransactions] = useState([]);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  const getTransactionsFromAPI = useCallback(async () => {
    if (walletAddress.length) {
      try {
        // this api is for testing
        // const res = await axios.get(`http://api.etherscan.io/api?module=account&action=txlist&address=0x2f00dF4F995451e0DF337B91744006EB8892bfB1&sort=asc`);
       
        //this api is for mainnet
        const res = await axios.get(`http://api.etherscan.io/api?module=account&action=txlist&address=${walletAddress[0]}&sort=asc`);
        return res.status === 200 ? res.data.result : []
      } catch (error) {
        console.log(error);
        return false;
      }
    }
  },[walletAddress]);

  // getting transactions history
  useEffect(() => {
    setTimeout(async () => {
      if (!transactions.length) setLoading(true);
      const newTransactions = await getTransactionsFromAPI();
      if ((typeof newTransactions) === "object" && newTransactions.length) {
        dispatch(setTransactions({ transactions: newTransactions }));
        const arr = await getNoOfDataFromArr(5, newTransactions);
        setVisibleTransactions(arr);
      }
      setLoading(false);
    }, 500);
  }, [walletAddress, dispatch, getTransactionsFromAPI, transactions.length]);

  const viewMore = async () => {
    const newArr = await getNoOfDataFromArr(20, transactions);
    setVisibleTransactions(newArr);
  }

  return (
    pug`
      Container
        ConnectionTitle Recent Transactions

        AllTransactions
          if loading
            FullScreenLoader(size=50 borderWidth=5)

          else
            if visibleTransactions.length
              each transaction in visibleTransactions
                SingleTransaction(key=transaction.hash data=transaction)

            else 
              Msg No Transaction found
        
        if !loading && visibleTransactions.length
          BtnContainer
            ViewMore(onClick=viewMore style={display:visibleTransactions.length < 20 ? 'inline-block' : 'none'}) View more
    `
  )
})